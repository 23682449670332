import { Box, Button, Card, CardActions, CardContent, Theme, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPut, LayoutContext } from "wcz-layout";
import Order from "../../models/order/Order";
import { OrderStatus } from "../../models/order/OrderStatus";
import { eqOrderUrl } from "../../utils/BaseUrl";
import WarehouseOrderCardRemark from "./common/WarehouseOrderCardRemark";
import ShortageOrderItem from "./shortageOrder/ShortageOrderItem";
import moment from "moment";

interface ShortageOrderProps {
    order: Order
}

export default function ShortageOrder(props: ShortageOrderProps) {
    const { order } = props;
    const [status, setStatus] = useState<OrderStatus>(OrderStatus.Created);
    const { t } = useContext(LayoutContext);

    useEffect(() => setStatus(order.status), [order.status]);

    const { mutate: updateOrderStatus } = useMutation((req: { id: string, status: OrderStatus }) => fetchPut(eqOrderUrl + "/v1/order/" + req.id, req));

    const updateStatus = (status: OrderStatus) => {
        updateOrderStatus({
            id: order.id as string,
            status: status
        });
        setStatus(status);
    };

    const getBorderStyle = (theme: Theme): string | undefined => {
        const borderStyle = "solid 5px ";
        switch (status) {
            case OrderStatus.Delivered: return borderStyle + theme.palette.success.main;
            case OrderStatus.Picked: return borderStyle + theme.palette.info.main;
            case OrderStatus.Rejected: return borderStyle + theme.palette.error.main;
        }
    };

    const handleOnButtonClick = () => {
        switch (order.status) {
            case OrderStatus.Created:
                return updateStatus(OrderStatus.Delivered);
        }
    };

    return (
        <Card variant="outlined" sx={{ my: 2, border: getBorderStyle }} key={order.id}>
            <CardContent>
                <Typography variant="h6">{order.customer}</Typography>
                <Typography variant="subtitle2"><b>{t("Created")}:</b> {moment(order.created).formatDateTime()}</Typography>
                {order.remark && <Typography variant="subtitle2"><b>{t("Remark")}:</b> {order.remark}</Typography>}

                {order.items.map(item => <ShortageOrderItem item={item} key={item.id} />)}
            </CardContent>

            {status === OrderStatus.Created &&
                <CardActions>
                    <Button color="error" onClick={() => updateStatus(OrderStatus.Rejected)}>{t("Reject")}</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant="contained" onClick={handleOnButtonClick}>{t("Ordered")}</Button>
                </CardActions>
            }

            <WarehouseOrderCardRemark order={order} open={status === OrderStatus.Rejected} />
        </Card>
    );
}
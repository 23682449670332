import { Box, Button, Card, CardActions, CardContent, Theme, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { fetchPut, LayoutContext } from "wcz-layout";
import Material from "../../models/Material";
import Order from "../../models/order/Order";
import { OrderStatus } from "../../models/order/OrderStatus";
import { eqOrderUrl } from "../../utils/BaseUrl";
import SignatureDialog from "../common/SignatureDialog";
import WarehouseOrderCardRemark from "./common/WarehouseOrderCardRemark";
import DepartmentDialog from "./purchaseOrder/DepartmentDialog";
import PurchaseOrderItem from "./purchaseOrder/PurchaseOrderItem";

interface WarehouseOrderCardProps {
    order: Order,
    allOrders: Order[],
    setAllOrders: (allOrders: Order[]) => void,
    allMaterials: Material[]
}

export default function PurchaseOrder(props: WarehouseOrderCardProps) {
    const { order, allMaterials } = props;
    const [status, setStatus] = useState<OrderStatus>(OrderStatus.Created);
    const [departmentOpen, setDepartmentOpen] = useState(false);
    const [signatureOpen, setSignatureOpen] = useState(false);
    const { t } = useContext(LayoutContext);
    const isActiveCycleCount = useMemo(() => {
        const orderMaterialId: string[] = order.items.map(i => i.materialId);
        const orderMaterials = allMaterials.filter(m => orderMaterialId.includes(m.id));
        return orderMaterials.some(m => m.hasActiveCycleCount);
    }, [order, allMaterials]);

    useEffect(() => setStatus(order.status), [order.status]);

    const { mutate: updateOrderStatus, isLoading: isUpdatingStatus } = useMutation((req: { id: string, status: OrderStatus }) => fetchPut(eqOrderUrl + "/v1/order/" + req.id, req));

    const updateStatus = (status: OrderStatus) => {
        updateOrderStatus({
            id: order.id as string,
            status: status
        });
        setStatus(status);
        if (status === OrderStatus.Delivered)
            setSignatureOpen(false);
    };

    const getBorderStyle = (theme: Theme): string | undefined => {
        const borderStyle = "solid 5px ";
        switch (status) {
            case OrderStatus.Delivered: return borderStyle + theme.palette.success.main;
            case OrderStatus.Picked: return borderStyle + theme.palette.info.main;
            case OrderStatus.Rejected: return borderStyle + theme.palette.error.main;
        }
    };

    const handleOnButtonClick = () => {
        switch (status) {
            case OrderStatus.Created:
                return updateStatus(OrderStatus.Picked);
            case OrderStatus.Picked:
                return setDepartmentOpen(true);
        }
    };

    const handleRejectOrder = () => {
        if (window.confirm(t("PurchaseOrderRejectConfirm"))) {
            updateStatus(OrderStatus.Rejected);
        }
    };

    return (
        <Card variant="outlined" sx={{ my: 2, border: getBorderStyle }}>
            <CardContent>
                <Typography variant="h6">{order.customer}</Typography>
                <Typography variant="subtitle2"><b>{t("Created")}:</b> {moment(order.created).formatDateTime()}</Typography>
                {order.remark && <Typography variant="subtitle2"><b>{t("Remark")}:</b> {order.remark}</Typography>}
                {order.addresses.map((adress, index) => <Typography variant="subtitle2"><b>{t(adress.key)}:</b> {adress.value}</Typography>)}

                {order.items.map(item => <PurchaseOrderItem item={item} order={order} allOrders={props.allOrders} setAllOrders={props.setAllOrders} key={item.id} allMaterials={allMaterials} />)}
            </CardContent>

            {(status === OrderStatus.Created || status === OrderStatus.Picked) &&
                <CardActions>
                    <Button color="error" onClick={handleRejectOrder} disabled={isUpdatingStatus}>{t("Reject")}</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant="contained" disabled={isActiveCycleCount || isUpdatingStatus} onClick={handleOnButtonClick}>{status === OrderStatus.Created ? t("PickedUp") : t("HandOver")}</Button>
                </CardActions>
            }

            <WarehouseOrderCardRemark order={order} open={status === OrderStatus.Rejected} />
            <SignatureDialog open={signatureOpen} onClose={() => setSignatureOpen(false)} orderId={order.id as string} onSuccess={() => updateStatus(OrderStatus.Delivered)} />
            <DepartmentDialog open={departmentOpen} setOpen={setDepartmentOpen} orderId={order.id as string} onSuccess={() => { setDepartmentOpen(false); setSignatureOpen(true); }} />
        </Card>
    );
}
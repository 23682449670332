import { Autocomplete, Container, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, LayoutContext } from "wcz-layout";
import BackdropLoading from "../components/common/BackdropLoading";
import MaterialCard from "../components/materialTransfer/MaterialCard";
import Location from "../models/Location";
import { eqRoomUrl } from "../utils/BaseUrl";

export default function MaterialTransfer() {
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [partNumber, setPartNumber] = useState<string>("");
    const { changeTitle, t } = useContext(LayoutContext);

    const { data: locations = [] } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location`, signal));

    const { data: location = {} as Location, refetch, isFetching } = useQuery<Location>(["location", selectedLocation?.id], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location/${selectedLocation!.id}`, signal), {
        enabled: !!selectedLocation?.id,
        cacheTime: 0
    });

    useEffect(() => changeTitle(t("MaterialTransfer")), []);

    return (
        <Container>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <Autocomplete
                        value={selectedLocation}
                        options={locations}
                        getOptionLabel={option => option.name}
                        onChange={(e, value) => { setSelectedLocation(value); setPartNumber(""); }}
                        fullWidth
                        renderInput={(params) => <TextField {...params} fullWidth label={t("Location")} />}
                    />
                </Grid>

                {location.materials?.length > 5 &&
                    <Grid item xs={12}>
                        <Autocomplete
                            value={partNumber}
                            options={location.materials.map(m => m.partNumber)}
                            onChange={(e, value) => setPartNumber(value ?? "")}
                            fullWidth
                            renderInput={(params) => <TextField {...params} fullWidth label="PN" />}
                        />
                    </Grid>
                }

                {location.materials?.filter(m => partNumber ? m.partNumber === partNumber : true).slice(0, 5).map(m =>
                    <Grid item xs={12} key={m.materialId}>
                        <MaterialCard id={m.materialId} quantity={m.quantity} location={location} locations={locations} refetch={refetch} />
                    </Grid>
                )}
            </Grid>
            {location.materials?.length !== undefined && <Typography sx={{ mt: 2, textAlign: 'right' }}>{t("TotalOnLocationNumber", { number: location.materials?.length })}</Typography>}

            <BackdropLoading open={isFetching} />
        </Container>
    );
}
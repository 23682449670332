import { Check, DocumentScanner, LocationOn, Person, QrCode } from "@mui/icons-material";
import { BottomNavigation, Box, Dialog, DialogContent, DialogTitle, Divider, Fab, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, styled } from "@mui/material";
import { groupBy } from "lodash";
import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { LayoutContext, isDevelopment } from "wcz-layout";
import { DialogScanner, useScanDetection } from "wcz-scan";
import CycleCount from "../../models/CycleCount";
import { CycleCountDetailStatus } from "../../models/enums/CycleCountDetailStatus";
import { useFinishCycleCount } from "../../services/CycleCountService";
import { useHasCamera } from "../../utils/UseHasCamera";
import { StartedCycleCountLocation } from "./startedCycleCount/StartedCycleCountLocation";
import { CycleCountType } from "../../models/enums/CycleCountType";

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

interface StartedCycleCountProps {
    cycleCount: CycleCount;
    refetch: () => void;
}

export const StartedCycleCount: React.FC<StartedCycleCountProps> = ({ cycleCount, refetch }) => {
    const { t, snackbar } = useContext(LayoutContext);
    const [locatedAtScannerOpen, setLocatedAtScannerOpen] = useState<boolean>(false);
    const [selectedLocatedAtId, setSelectedLocatedAtId] = useState<string>('');
    const navigate = useNavigate();
    const [beep] = useSound(require('../../utils/sounds/beep.mp3'));
    const hasCamera = useHasCamera();
    const isEmployeeCycleCount = useMemo(() => cycleCount.type === CycleCountType.EmployeeCustom || cycleCount.type === CycleCountType.EmployeeFull, [cycleCount]);

    useScanDetection({
        enabled: locatedAtScannerOpen,
        onComplete: value => handleOnLocatedAtScan(value)
    });

    const openLocatedAtScanner = useCallback(() => setLocatedAtScannerOpen(true), []);

    const handleOnLocatedAtScan = useCallback((text: string) => {
        const cycleCountDetailLocation = cycleCount.cycleCountDetails.find(d => d.locatedAt.includes(text));
        if (cycleCountDetailLocation) {
            setSelectedLocatedAtId(cycleCountDetailLocation.locatedAtId);
            beep();
        }
        else
            snackbar({ message: t("LocationNotFound", { location: text }), severity: "error" });
    }, [cycleCount]);

    const { mutate: finish } = useFinishCycleCount({
        onSuccess: () => {
            navigate('/cycle-counts');
            snackbar({ message: t("Finished") });
        }
    });

    const groupedLocatedAtId = Object.keys(groupBy(cycleCount.cycleCountDetails, "locatedAtId"));
    const anyWaiting = cycleCount.cycleCountDetails.some(d => d.status === CycleCountDetailStatus.Waiting);

    const handleOnListItemClick = useCallback((locatedAtId: string) => () => {
        if (isDevelopment || isEmployeeCycleCount || !anyWaiting)
            setSelectedLocatedAtId(locatedAtId);
    }, [anyWaiting]);

    const nextLocatedAt = useMemo(() => {
        const nextLocationId = groupedLocatedAtId[groupedLocatedAtId.indexOf(selectedLocatedAtId) + 1];
        return cycleCount.cycleCountDetails.find(d => d.locatedAtId === nextLocationId)?.locatedAt;
    }, [groupedLocatedAtId, cycleCount]);

    if (selectedLocatedAtId)
        return <StartedCycleCountLocation
            cycleCount={cycleCount}
            selectedLocatedAtId={selectedLocatedAtId}
            setSelectedLocatedAtId={setSelectedLocatedAtId}
            refetch={refetch}
            groupedLocatedAtId={groupedLocatedAtId}
            finishCycleCount={() => finish(cycleCount.id)}
            anyWaiting={anyWaiting}
            nextLocatedAt={nextLocatedAt}
        />;

    return (
        <Box sx={{ mt: 1 }}>
            <List subheader={<Typography variant="h6" gutterBottom sx={{ mb: 1, ml: 1 }}>{t("Locations")}:</Typography>}>
                {groupedLocatedAtId.map((locatedAtId) => {
                    const detailsForThisLocation = cycleCount.cycleCountDetails.filter(detail => detail.locatedAtId === locatedAtId);

                    const value = detailsForThisLocation[0];
                    if (!value) return null;

                    const hasWaitingMaterial = detailsForThisLocation.some(d => d.status === CycleCountDetailStatus.Waiting);

                    return (
                        <Fragment key={locatedAtId}>
                            <ListItemButton onClick={handleOnListItemClick(locatedAtId)}>
                                <ListItemIcon>
                                    {isEmployeeCycleCount ?
                                        <Person color={hasWaitingMaterial ? undefined : "success"} />
                                        :
                                        <LocationOn color={hasWaitingMaterial ? undefined : "success"} />
                                    }
                                </ListItemIcon>
                                <ListItemText primary={value.locatedAt} secondary={hasWaitingMaterial ? undefined : t("Finished")} />
                            </ListItemButton>
                            <Divider />
                        </Fragment>
                    );
                })}
            </List>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels>
                    {anyWaiting ?
                        <StyledFab color="primary" onClick={openLocatedAtScanner}>
                            <QrCode />
                        </StyledFab>
                        :
                        <StyledFab color="primary" onClick={() => finish(cycleCount.id)}>
                            <Check />
                        </StyledFab>
                    }
                </BottomNavigation>
            </Paper>

            {hasCamera ?
                <DialogScanner open={locatedAtScannerOpen} onResult={handleOnLocatedAtScan} setOpen={setLocatedAtScannerOpen} title={`${t("ScanLocation")}: ${nextLocatedAt}`} />
                :
                <Dialog open={locatedAtScannerOpen}>
                    <DialogTitle>{`${t("ScanLocation")}: ${nextLocatedAt}`}</DialogTitle>
                    <DialogContent sx={{ textAlign: "center" }}>
                        <DocumentScanner fontSize="large" color="action" />
                    </DialogContent>
                </Dialog>
            }
        </Box>
    );
};
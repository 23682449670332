import { isDevelopment } from 'wcz-layout';

let eqRoomMaterialUrl: string = "https://api.dev.wistron.eu/wscz-eq-material";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";
let eqOrderUrl = "https://api.dev.wistron.eu/eq-order";
let eqRoomUrl = "https://api.dev.wistron.eu/eq-room";
let eqOrderAddressUrl = "https://api.dev.wistron.eu/wscz-eq-order-address";
let fileUrl: string = "https://api.dev.wistron.eu/file";

if (!isDevelopment) {
    eqRoomMaterialUrl = "https://api.wistron.eu/wscz-eq-material";
    peoplesoftUrl = "https://api.wistron.eu/ps";
    eqOrderUrl = "https://api.wistron.eu/eq-order";
    eqRoomUrl = "https://api.wistron.eu/eq-room";
    eqOrderAddressUrl = "https://api.wistron.eu/wscz-eq-order-address";
    fileUrl = "https://api.wistron.eu/file";
}

export { eqRoomMaterialUrl, peoplesoftUrl, eqOrderUrl, eqRoomUrl, eqOrderAddressUrl, fileUrl };
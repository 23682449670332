import { Box, Drawer, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { LayoutContext } from "wcz-layout";
import { OrderType } from "../../../models/order/OrderType";
import Cart from "../../../models/shoppingCart/Cart";
import CartItemDetail from "./cartDrawer/CartItemDetail";
import OrderForm from "./cartDrawer/OrderForm";

interface CartDrawerProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    cart: Cart | undefined,
    refetch: () => void,
    type: OrderType
}

export default function CartDrawer(props: CartDrawerProps) {
    const { t } = useContext(LayoutContext);

    return (
        <Drawer anchor="right" open={props.open} onClose={() => props.setOpen(false)}>
            <Box sx={{ width: { xs: "80vw", sm: "60vw", md: "50vw", lg: "40vw", xl: "30vw" }, px: 2 }} role="presentation">
                <Grid container sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{props.type === OrderType.Purchase ? t("Cart") : t("Return")}</Typography>
                    </Grid>

                    {props.cart?.items.map(item => <CartItemDetail key={item.id} item={item} type={props.type} onSettled={props.refetch} />)}

                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Typography variant="h5">{t("Form")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <OrderForm refetch={props.refetch} type={props.type} setOpen={props.setOpen} cart={props.cart} />
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
}
import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FileViewer } from "wcz-file";
import { fetchGet } from "wcz-layout";
import Order from "../../models/order/Order";
import { eqOrderUrl } from "../../utils/BaseUrl";
import MaterialDetailS from "../common/MaterialDetailS";

interface OrderDetailsProps {
    orderId: string,
    setOrderId: (orderId: string) => void
}

export default function OrderDetails(props: OrderDetailsProps) {
    const { orderId, setOrderId } = props;

    const { data: order = {} as Order } = useQuery<Order>(["order", orderId], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order/${orderId}`, signal), {
        enabled: !!orderId
    });

    const onClose = () => setOrderId("");

    const getOrderItemQuantity = (itemId: string | undefined): number => {
        if (!itemId) return 0;

        const orderItem = order.items?.find(i => i.id === itemId);
        if (orderItem)
            return orderItem.quantity;

        return 0;
    };

    return (
        <Dialog open={!!orderId} onClose={onClose} fullWidth>
            <Grid container justifyContent="space-between" sx={{ height: 50 }}>
                <Grid item>
                    <DialogTitle>{order.customer}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {order.items?.map((item) =>
                        <ListItem key={item.id} disablePadding>
                            <ListItemText primary={<MaterialDetailS materialId={item.materialId} quantity={getOrderItemQuantity(item.id)} />} />
                        </ListItem>
                    )}
                    <Divider />
                    <FileViewer disableActions subId={orderId} />
                </List>
            </DialogContent>
        </Dialog>
    );
}
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";

interface BackdropLoadingProps {
    open: boolean,
}

export default function BackdropLoading(props: BackdropLoadingProps) {

    return (
        <Backdrop open={props.open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                    <CircularProgress color="inherit" />
                </Grid>

                <Grid item xs={12}>
                    <Typography color="inherit" variant="h6">Just a moment...</Typography>
                </Grid>
            </Grid>
        </Backdrop>
    );
}
import { Add } from '@mui/icons-material';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { fetchPost, fetchPut, LayoutContext } from 'wcz-layout';
import Item from '../../../models/Item';
import Material, { MaterialLocation } from '../../../models/Material';
import { eqRoomMaterialUrl, eqRoomUrl } from '../../../utils/BaseUrl';

interface AddSerialNumber {
    serialNumber: string,
    vendorSn: string
}

interface MaterialReceivingLocationCardProps {
    materialLocation: MaterialLocation,
    material: Material,
    materialItems: Item[],
    refetch: () => void
}

export default function MaterialReceivingLocationCard(props: MaterialReceivingLocationCardProps) {
    const [isFocused, setIsFocused] = useState(false);
    const [addSerialNumber, setAddSerialNumber] = useState({} as AddSerialNumber);
    const [snDialogOpen, setSnDialogOpen] = useState(false);
    const [addQuantity, setAddQuantity] = useState(1);
    const { t } = useContext(LayoutContext);

    const { mutate: createItem } = useMutation((req: any) => fetchPost(eqRoomMaterialUrl + "/v1/item", req), {
        onSuccess: (data: Item) => addItemToLocation({ itemId: data.id, materialId: props.material.id, locationId: props.materialLocation.id })
    });

    const { mutate: addItemToLocation } = useMutation((req: any) => fetchPost(eqRoomUrl + "/v1/material/item", req), {
        onSuccess: () => {
            setAddSerialNumber({} as AddSerialNumber);
            props.refetch();
            setSnDialogOpen(false);
        }
    });

    const requestSerialNumber = () => {
        createItem({
            serialNumber: addSerialNumber.serialNumber,
            vendorSerialNumber: addSerialNumber.vendorSn,
            materialId: props.material.id
        });
    };

    const openSnDialog = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            setSnDialogOpen(true);
        }
    };

    const confirmAddSn = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            requestSerialNumber();
        }
    };

    const { mutate: addMaterialQuantity, isLoading: isMutating } = useMutation((request: any) => fetchPut(`${eqRoomUrl}/v1/material/${request.materialId}/quantity`, request), {
        onSettled: () => props.refetch()
    });

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter')
            updateMaterialQuantity();
    };

    const updateMaterialQuantity = () => {
        const materialLocation: MaterialLocation | undefined = props.material.locations?.find(l => l.id === props.materialLocation.id);
        if (!materialLocation)
            return;

        const request = {
            materialId: props.material.id,
            locationId: props.materialLocation.id,
            quantity: materialLocation.quantity + addQuantity
        };

        addMaterialQuantity(request);
    };

    return (
        <React.Fragment>
            <Card raised={isFocused} sx={{ my: 1 }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={7}>
                            <Typography variant="h6">{t("Location")}: {props.materialLocation.name}</Typography>
                            {props.material.hasItems ?
                                <Typography variant="subtitle2"><b>{t("ActualQuantity")}:</b> {props.materialItems.filter(i => i.location?.id === props.materialLocation.id).length} {t("Pcs")}</Typography>
                                :
                                <Typography variant="subtitle2"><b>{t("ActualQuantity")}:</b> {props.material.locations?.find(l => l.id === props.materialLocation.id)?.quantity} {t("Pcs")}</Typography>
                            }
                        </Grid>

                        {!props.material.hasItems &&
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <TextField variant="standard" size="small" type="number" fullWidth onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
                                    value={addQuantity} onChange={e => setAddQuantity(Number(e.target.value))} onKeyDown={handleOnKeyDown} label={t("ReceivingQuantity")}
                                    InputProps={{
                                        startAdornment: <IconButton size="small" disabled={isMutating} onClick={updateMaterialQuantity}><Add /></IconButton>,
                                        endAdornment: <InputAdornment position="start">{t("Pcs")}</InputAdornment>,
                                    }} />
                            </Grid>
                        }

                        {props.material.hasItems &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle2"><b>{t("LastScanned")}:</b> {orderBy(props.materialItems, ["createdDate"], ['desc']).slice(0, 1).map(i => i.serialNumber)}</Typography>

                                <TextField variant="standard" fullWidth onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} sx={{ mt: 2 }}
                                    value={addSerialNumber.serialNumber ?? ""} onChange={e => setAddSerialNumber({ ...addSerialNumber, serialNumber: e.target.value })} onKeyDown={openSnDialog}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">SN:</InputAdornment>,
                                        endAdornment: <IconButton size="small" onClick={() => setSnDialogOpen(true)}><Add /></IconButton>,
                                    }} />
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>

            <Dialog open={snDialogOpen} onClose={() => setSnDialogOpen(false)} fullWidth>
                <DialogTitle>SN: {addSerialNumber.serialNumber}</DialogTitle>
                <DialogContent>
                    <TextField variant="standard" label={`${t("Vendor")} SN`} fullWidth autoFocus onKeyDown={confirmAddSn}
                        value={addSerialNumber.vendorSn} onChange={e => setAddSerialNumber({ ...addSerialNumber, vendorSn: e.target.value })} />
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={requestSerialNumber}>{t("Save")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
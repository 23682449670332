import { useEffect, useState } from "react";

const constraints: MediaStreamConstraints = {
    video: true,
    audio: false,
};

export const useHasCamera = () => {
    const [hasCamera, setHasCamera] = useState<boolean>(false);

    useEffect(() => {
        checkCamera();
    }, []);

    const checkCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (stream) {
                setHasCamera(true);
                stream.getTracks().forEach(track => track.stop());
            }
        } catch {
            setHasCamera(false);
        }
    };

    return hasCamera;
};
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { LayoutContext, fetchGet } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function ItemReport() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle("Item Report"), []);

    const { data = [], isLoading } = useQuery(["itemReport"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/report/item`, signal), {
        refetchOnWindowFocus: true
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'serialNumber', headerName: "SN", width: 180 },
        { field: 'partNumber', headerName: "PN", width: 180 },
        { field: 'description', headerName: t("Description"), width: 400 },
        { field: 'keeper', headerName: t("Keeper"), width: 220, },
        { field: 'vendorSerialNumber', headerName: `${t("Vendor")} SN`, width: 180, },
        { field: 'remark', headerName: t("Remark"), width: 350, },
    ] as GridColDef[], [i18n.resolvedLanguage]);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "itemReport", export: true, } as GridToolbarProps }} loading={isLoading} />
        </TableContainer>
    );
}
import { Autocomplete, TextField, darken, lighten, styled } from "@mui/material";
import { useIsFetching, useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { LayoutContext, fetchDelete, fetchGet, fetchPost } from "wcz-layout";
import packageJson from "../../../package.json";
import Location from "../../models/Location";
import Material, { MaterialLocation } from "../../models/Material";
import { eqRoomMaterialUrl, eqRoomUrl } from "../../utils/BaseUrl";
import { sortBy } from "lodash";

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

interface MaterialLocationEditProps {
    material: Material,
    setMaterial: (material: Material) => void
}

export default function MaterialLocationEdit(props: MaterialLocationEditProps) {
    const [materialLocations, setMaterialLocations] = useState<MaterialLocation[]>([]);
    const open = useMemo(() => !!props.material.id, [props.material.id]);
    const { snackbar, t } = useContext(LayoutContext);
    const isFetching: boolean = !!useIsFetching();
    const isMutating: boolean = !!useIsMutating();

    const { data: locations = [] } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location?appName=${packageJson.name}&onlyFree=true&includeMaterialId=${props.material.id}`, signal), {
        enabled: open,
        select: data => sortBy(data, d => d.canHaveMoreMaterial)
    });

    const { refetch } = useQuery<Material>(["material", props.material.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${props.material.id}`, signal), {
        enabled: !!props.material.id,
        onSuccess: data => setMaterialLocations(data.locations ?? []),
    });

    const handleOnChange = (values: Location[]) => {
        const toAdd = values.find(value => !materialLocations.find(location => location.name === value.name));
        if (toAdd)
            add({ materialId: props.material.id, locationId: toAdd.id });

        const toRemove = materialLocations.find(location => !values.find(value => value.name === location.name));
        if (toRemove)
            remove({ materialId: props.material.id, locationId: toRemove.id });
    };


    const { mutate: add } = useMutation((req: { materialId: string, locationId: string }) => fetchPost(`${eqRoomUrl}/v1/material`, req), {
        onSuccess: () => refetch(),
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); },
    });

    const { mutate: remove } = useMutation((req: { materialId: string, locationId: string }) => fetchDelete(`${eqRoomUrl}/v1/material/${req.materialId}/location/${req.locationId}`), {
        onSuccess: () => refetch(),
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); },
    });

    const value = useMemo(() => materialLocations.map(location => locations.find(l => l.id === location.id)).filter(l => l) as Location[], [materialLocations, locations]);

    return (
        <Autocomplete
            value={value}
            options={locations}
            getOptionLabel={option => option.name}
            onChange={(e, value) => handleOnChange(value)}
            autoHighlight
            multiple
            disabled={isFetching || isMutating}
            renderInput={(params) => <TextField {...params} label={t("Locations")} variant="standard" />}
            groupBy={(option) => option.canHaveMoreMaterial ? t("CanHaveMoreMaterial") : t("Free")}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
        />
    );
}
import { Delete } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import { ReturnContext } from "../../../contexts/ReturnContext";
import Item from "../../../models/Item";
import { eqRoomMaterialUrl } from "../../../utils/BaseUrl";

interface ReturnMaterialItemProps {
    itemId: string,
}

export default function ReturnMaterialItem(props: ReturnMaterialItemProps) {
    const { itemId } = props;
    const returnContext = useContext(ReturnContext);

    const { data: item = {} as Item } = useQuery<Item, Response>(["item", itemId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/${itemId}`, signal), {
        enabled: !!itemId,
    });

    return (
        <Typography sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
            SN: {item.serialNumber}
            <IconButton size="small" onClick={() => returnContext.removeItem(itemId)}>
                <Delete />
            </IconButton>
        </Typography>
    );
}
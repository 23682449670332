import { Typography } from "@mui/material";
import { Fragment, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, LayoutContext } from "wcz-layout";
import Material from "../../models/Material";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";

interface MaterialDetailSProps {
    materialId: string,
    quantity: number
}

export default function MaterialDetailS(props: MaterialDetailSProps) {
    const { materialId, quantity } = props;
    const { t } = useContext(LayoutContext);

    const { data: material = {} as Material } = useQuery<Material>(["material", materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${materialId}`, signal), {
        enabled: !!materialId
    });

    return (
        <Fragment>
            {material.partNumber && <Typography variant="subtitle1"><b>PN: {material.partNumber} ({quantity}{t("Pcs").toLowerCase()})</b></Typography>}
            {material.description && <Typography variant="subtitle2" color="text.secondary"><b>{t("Description")}: </b>{material.description}</Typography>}
        </Fragment>
    );
}
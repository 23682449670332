import { Add, Download, FileUpload } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { GridRowId, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiContext } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";
import { fetchPost, LayoutContext, newGuid } from "wcz-layout";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

const getFirstEditableColumn = (apiRef: GridApiPremium) => {
    const editableColumns = apiRef.getAllColumns().filter(column => column.editable);
    if (editableColumns.length)
        return editableColumns[0];
};

export function ToolingPartGridToolbar() {
    const apiRef = useGridApiContext();
    const { snackbar, t } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    const handleOnAddRowClick = useCallback(() => {
        const rowIds: GridRowId[] = apiRef.current.getAllRowIds();
        const allRows: any[] = rowIds.map(rowId => (apiRef.current.getRow(rowId)));

        const id = newGuid();
        apiRef.current.setRows([{ id, isNew: true }, ...allRows]);
        setTimeout(() => apiRef.current.startRowEditMode({ id }));

        const firstEditableColumn = getFirstEditableColumn(apiRef.current);
        if (firstEditableColumn) {
            apiRef.current.setCellFocus(id, firstEditableColumn.field);
            setTimeout(() => apiRef.current.scrollToIndexes({ rowIndex: 0 }));
        }
    }, [apiRef.current]);

    const handleExport = useCallback(() => apiRef.current.exportDataAsCsv({ utf8WithBom: true }), [apiRef.current]);

    const handleOnImport = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files)
            return;

        let formData: FormData = new FormData();
        formData.append('file', e.target.files[0]);

        fetchPost(`${eqRoomMaterialUrl}/v1/toolingpart/import`, formData)
            .catch(e => {
                const error = String(e);
                if (error.includes("Unexpected end of JSON input")) {
                    snackbar({ message: t("SuccessfullyUploaded") });
                    queryClient.invalidateQueries({ queryKey: ["toolingparts", "search"] });
                    queryClient.invalidateQueries({ queryKey: ["materialFilter"] });
                }
                else
                    snackbar({ message: error, severity: "error" });
            });
    }, []);

    const hasActiveFilters = useMemo(() => !!apiRef.current.state.filter.filterModel.items.length || !!apiRef.current.state.filter.filterModel.quickFilterValues?.length, [apiRef.current.state.filter.filterModel]);

    return (
        <Grid container justifyContent="space-between" sx={{ p: 0.5 }}>
            <Grid item>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button color="primary" size="small" startIcon={<Add />} onClick={handleOnAddRowClick} disabled={hasActiveFilters}>{t("DataGrid.AddRecord")}</Button>
                <Button color="primary" size="small" startIcon={<Download />} onClick={handleExport}>Export</Button>
                <Button color="primary" size="small" component="label" startIcon={<FileUpload />}>
                    Import
                    <input hidden type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleOnImport} />
                </Button>
            </Grid>

            <Grid item>
                <GridToolbarQuickFilter />
            </Grid>
        </Grid>
    );
}
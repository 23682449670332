import React from 'react';
import { Grid, Card, CardActionArea, CardHeader } from '@mui/material';

interface StyledCardProps {
    onClick: () => void,
    title: string,
    children?: React.ReactNode
}

export default function StyledCard(props: StyledCardProps) {

    return (
        <Grid item xs={12} sm={6} lg={3}>
            <Card variant="outlined" onClick={props.onClick}>
                <CardActionArea>
                    <CardHeader title={props.title} avatar={props.children} />
                </CardActionArea>
            </Card>
        </Grid>
    );
}
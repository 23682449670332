import { Button } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useMemo, useState } from "react";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import Material from "../../models/Material";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";
import BaseForm from "./common/BaseForm";
import MaterialLocationEdit from "./MaterialLocationEdit";

interface EditMaterialDialogProps {
    materialId: string;
    setMaterialId: (materialId: string) => void;
    refetch: () => void;
}

export default function EditMaterialDialog(props: EditMaterialDialogProps) {
    const { materialId, setMaterialId, refetch } = props;
    const [material, setMaterial] = useState<Material>({} as Material);
    const { t } = useContext(LayoutContext);

    useQuery<Material>(["material", materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${materialId}`, signal), {
        enabled: !!materialId,
        onSuccess: data => setMaterial(data)
    });

    const { mutate, isLoading: isMutating } = useMutation((req: Material) => fetchPut(`${eqRoomMaterialUrl}/v1/material/${materialId}`, req), {
        onSuccess: () => {
            refetch();
            handleClose();
        }
    });

    const handleSubmit = useCallback(() => {
        let newMaterial: Material = {
            ...material,
            description: material.description?.trim(),
            partNumber: material.partNumber?.trim(),
            vendorPartNumber: material.vendorPartNumber?.trim(),
            project: material.project?.trim(),
            vendor: material.vendor?.trim(),
            type: material.type?.trim(),
            remark: material.remark?.trim(),
            models: material.models?.map(model => typeof model === 'string' ? { name: model } : model),
            subCategory: typeof material.subCategory === 'string' ? { name: material.subCategory } : material.subCategory,
            safetyStock: material.safetyStock ? Number(material.safetyStock) : 0
        };

        newMaterial.models = newMaterial.models?.map(m => ({ ...m, name: m.name.trim() }));
        newMaterial.subCategory = { ...newMaterial.subCategory, name: newMaterial.subCategory.name.trim() };

        mutate(newMaterial);
    }, [material]);

    const handleClose = useCallback(() => {
        setMaterialId("");
        setMaterial({} as Material);
    }, []);

    const isValid = useMemo(() => !!material.partNumber && !!material.category && !!material.subCategory && !!material.description && !!material.vendor && !!material.vendorPartNumber, [material]);

    return (
        <CustomDialog open={!!materialId} onClose={handleClose} title={t("UpdateMaterial")} color="primary" maxWidth="lg" dialogActions={[
            <Button key="update" onClick={handleSubmit} disabled={isMutating || !isValid}>{t("Update")}</Button>
        ]}>
            <BaseForm material={material} setMaterial={setMaterial} />
            <MaterialLocationEdit material={material} setMaterial={setMaterial} />
        </CustomDialog>
    );
}
import { Box, Fade } from "@mui/material";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useCallback } from "react";

interface Props {
    children: React.ReactElement;
}

export default function ScrollTop(props: Props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    const handleClick = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fade in={trigger}>
            <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }} >
                {children}
            </Box>
        </Fade>
    );
}
import { Autocomplete, AutocompleteRenderInputParams, TextField, TextFieldProps } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { fetchDelete, fetchPost, LayoutContext } from "wcz-layout";
import Material, { MaterialFilter } from "../../models/Material";
import Model from "../../models/Model";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";

const textFieldProps: TextFieldProps = {
    variant: 'standard',
    fullWidth: true,
    size: 'small'
};

interface MaterialModelUpdateProps {
    material: Material,
    setMaterial: (material: Material) => void,
    suggestions: MaterialFilter,
    refetchSuggestions: () => void,
}

export default function MaterialModelUpdate(props: MaterialModelUpdateProps) {
    const { material, setMaterial, suggestions, refetchSuggestions } = props;
    const { t } = useContext(LayoutContext);

    const handleOnChange = (values: string[]) => {
        const removeModel: Model | undefined = material.models?.find(model => !values.includes(model.name));
        if (removeModel)
            return remove(removeModel);

        const addNewModel: string | undefined = values.find(value => !material.models?.map(model => model.name).includes(value));
        if (addNewModel) {
            const existingModel: Model | undefined = suggestions.models.find(o => o.name === addNewModel);
            if (existingModel)
                add(existingModel);
            else
                add({ name: addNewModel });
        }
    };

    const { mutate: add, isLoading: isAdding } = useMutation((req: Model) => fetchPost(`${eqRoomMaterialUrl}/v1/material/${material.id}/model`, req), {
        onSuccess: (data: Material) => {
            refetchSuggestions();
            setMaterial({ ...material, models: data.models });
        },
    });

    const { mutate: remove, isLoading: isRemoving } = useMutation((req: Model) => fetchDelete(`${eqRoomMaterialUrl}/v1/material/${material.id}/model/${req.id}`), {
        onSuccess: (data, variables) => {
            refetchSuggestions();
            setMaterial({
                ...material,
                models: material.models?.filter(m => m.id !== variables.id)
            });
        },
    });

    const renderInput = useCallback((label: string, required?: boolean) => (params: AutocompleteRenderInputParams) =>
        <TextField {...params} {...textFieldProps} required={required} label={label} />, []);

    return (
        <Autocomplete
            value={material.models?.map(m => m.name) ?? []}
            onChange={(e, value) => handleOnChange(value)}
            options={suggestions.models?.map(o => o.name) ?? []}
            autoHighlight
            freeSolo
            multiple
            disabled={isAdding || isRemoving}
            renderInput={renderInput(t("Models"))}
        />
    );
}
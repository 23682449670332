import { Button, ButtonGroup, Card, CardHeader, Container, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext, fetchDelete, fetchGet, fetchPut } from "wcz-layout";
import ReturnOrderItem from "../../components/order/ReturnOrderItem";
import Return from "../../models/return/Return";
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function ReturnOrders() {
    const { snackbar, changeTitle, t } = useContext(LayoutContext);
    const isMutating = !!useIsMutating();
    const [highlightOrders, setHighlightOrders] = useState<{ id: string, highlight: boolean }[]>([]);

    const { data = [], refetch, isFetching } = useQuery<Return[]>(["returnOrders"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/return`, signal));

    useEffect(() => changeTitle(t("ReturnOrders")), []);

    const { mutate: confirm, isLoading: isConfirming } = useMutation((req: Return) => fetchPut(eqOrderUrl + "/v1/return/" + req.id, req), {
        onSuccess: () => { snackbar({ message: t("Confirmed"), severity: "success" }); refetch(); },
    });

    const { mutate: reject, isLoading: isRejecting } = useMutation((req: Return) => fetchDelete(eqOrderUrl + "/v1/return/" + req.id), {
        onSuccess: () => { snackbar({ message: t("Deleted"), severity: "success" }); refetch(); },
    });

    const rejectReturn = (request: Return) => {
        if (window.confirm(t("Continue?")!))
            reject(request);
    };

    const remaining = useCallback((order: Return) => {
        const totalQuantity = order.items.reduce((acc, curr) => acc + curr.quantity, 0);

        const materialItemsPickedQuantity = order.items.flatMap(i => i.materialItems).filter(mi => mi.locationId).length;
        const locationsSumPickedQuantity = order.items.flatMap(i => i.locations).reduce((acc, curr) => acc + curr.quantity, 0);

        return totalQuantity - (materialItemsPickedQuantity + locationsSumPickedQuantity);
    }, [isMutating, isFetching]);

    const confirmReturn = (request: Return) => {
        if (!request.id) return;

        if (remaining(request)) {
            if (!highlightOrders.map(ho => ho.id).includes(request.id))
                setHighlightOrders([...highlightOrders, { id: request.id, highlight: true }]);

            return;
        }

        request.isConfirmed = true;
        confirm(request);
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 2 }}>
            <Grid container justifyContent="center">
                {data.map(returnOrder => {
                    const highlight: boolean = highlightOrders.find(ho => ho.id === returnOrder.id)?.highlight ?? false;

                    return (
                        <Grid item xs={12} sx={{ my: 1 }} key={returnOrder.id}>
                            <Card>
                                <CardHeader title={returnOrder.returningUser} subheader={<Typography variant="subtitle2" color="text.secondary"><b>{t("Created")}: </b>{moment(returnOrder.created).formatDateTime()}</Typography>}
                                    sx={theme => ({ bgcolor: theme.palette.mode === "dark" ? grey[800] : grey[200] })} />

                                {returnOrder.items.map(item => <ReturnOrderItem item={item} refetch={refetch} returnOrder={returnOrder} key={item.id} highlight={highlight} />)}

                                {!!remaining(returnOrder) && <Typography sx={{ ml: 1 }}>{t("RemainsToAssign")}: {remaining(returnOrder)}</Typography>}
                                <ButtonGroup fullWidth>
                                    <Button onClick={() => confirmReturn(returnOrder)} color="success" disabled={isConfirming || isRejecting}>{t("Confirm")}</Button>
                                    <Button onClick={() => rejectReturn(returnOrder)} color="error" disabled={isConfirming || isRejecting}>{t("Reject")}</Button>
                                </ButtonGroup>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
}
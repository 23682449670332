import { Cached, Favorite, FavoriteBorder } from '@mui/icons-material';
import { Box, Button, Card, CardActions, Checkbox, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FileViewer } from 'wcz-file';
import { fetchDelete, fetchPost, LayoutContext, TypographyWithIcon } from 'wcz-layout';
import Material from '../../models/Material';
import { eqOrderUrl } from '../../utils/BaseUrl';

interface FavouriteMaterial {
    employeeId: string,
    materialId: string
}

interface StoreItemCardProps {
    material: Material,
    setAddMaterial: (addMaterial: string) => void
}

export default function StoreItemCard(props: StoreItemCardProps) {
    const [favourite, setFavourite] = useState(false);
    const { user, t } = useContext(LayoutContext);

    useEffect(() => setFavourite(props.material.favourite ?? false), [props.material.favourite]);

    const { mutate: create } = useMutation(() => fetchPost(`${eqOrderUrl}/v1/FavouriteMaterial`, { employeeId: user.id, materialId: props.material.id } as FavouriteMaterial), {
        onSuccess: () => setFavourite(true)
    });

    const { mutate: remove } = useMutation(() => fetchDelete(`${eqOrderUrl}/v1/FavouriteMaterial/${props.material.id}`), {
        onSuccess: () => setFavourite(false)
    });

    return (
        <Card square>
            <Grid container direction="column" justifyContent="space-between" sx={{ minHeight: 350 }}>
                <Grid item>
                    <FileViewer subId={props.material.id} disableActions />

                    <Box sx={{ px: 1 }}>
                        {props.material.subCategory && <Typography variant="h6">{props.material.subCategory.name}</Typography>}
                        {props.material.partNumber && <TypographyWithIcon endIcon={props.material.exchange && <Cached color='primary' />} color="text.secondary"><b>PN: </b>{props.material.partNumber}</TypographyWithIcon>}
                        {props.material.type && <Typography color="text.secondary"><b>{t("Type")}: </b>{props.material.type}</Typography>}
                        {props.material.description && <Typography color="text.secondary"><b>{t("Description")}: {props.material.description}</b></Typography>}
                        {!!props.material.models?.length && <Typography color="text.secondary"><b>{t("Models")}: </b>{props.material.models?.slice(0, 2).map((m, i, arr) => <Chip key={m.id} size="small" label={m.name} sx={{ mr: (i + 1) === arr.length ? undefined : 1 }} />)}
                            {props.material.models?.length > 2 &&
                                <Tooltip title={<Fragment>{props.material.models?.slice(2).map(m => <p key={m.id}>{m.name}</p>)}</Fragment>}>
                                    <Chip color="primary" size="small" label={t("More")} sx={{ ml: 1, cursor: 'pointer' }} />
                                </Tooltip>
                            }
                        </Typography>}
                        {props.material.locations?.length && <Typography color="text.secondary"><b>{t("Location")}: </b>{props.material.locations.filter(l => l.name !== "HOLD" && l.name !== "SCRAP").map((l, i) => `${i ? " " : ""}${l.name}`)}</Typography>}
                        <Typography color="text.secondary"><b>Qty: </b>{props.material.usableQuantity}</Typography>
                    </Box>
                </Grid>

                <Grid item>
                    <CardActions disableSpacing>
                        <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} color="error" checked={favourite} onChange={e => e.target.checked ? create() : remove()} />
                        <div style={{ flexGrow: 1 }} />
                        <Button onClick={() => props.setAddMaterial(props.material.id)} variant="contained">{t("AddToCart")}</Button>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
}
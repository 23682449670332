import { DataGridPremium, GridColDef, GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import { EditableHeader, editInputCellValidation, GridDeleteCellItem, GridToolbar, TableContainer } from 'wcz-x-data-grid';
import Location from "../models/Location";
import { eqRoomUrl } from "../utils/BaseUrl";

export default function Locations() {
    const { changeTitle, t } = useContext(LayoutContext);

    const { data: locations = [], isLoading } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location`, signal));

    useEffect(() => changeTitle(t("Locations")), [t]);

    const { mutateAsync: create } = useMutation((req: Location) => fetchPost(`${eqRoomUrl}/v1/location`, req));
    const { mutateAsync: update } = useMutation((req: Location) => fetchPut(`${eqRoomUrl}/v1/location/${req.id}`, req));
    const remove = useMutation((id: GridRowId) => fetchDelete(`${eqRoomUrl}/v1/location/${id}`));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridDeleteCellItem id={params.id} remove={remove} />
            ],
        },
        { field: 'name', headerName: t("Name"), width: 220, editable: true, renderHeader: EditableHeader, preProcessEditCellProps: editInputCellValidation.hasLength, },
        { field: 'canHaveMoreMaterial', headerName: t("CanHaveMoreMaterial"), width: 250, editable: true, renderHeader: EditableHeader, type: 'boolean' },
    ] as GridColDef[], [t]);

    const processRowUpdate = async (row: GridRowModel<Location>): Promise<Location> => {
        row.isNew ? create(row) : update(row);
        return { ...row, isNew: false };
    };

    return (
        <TableContainer>
            <DataGridPremium rows={locations} columns={columns} components={{ Toolbar: GridToolbar }} editMode="row" processRowUpdate={processRowUpdate} loading={isLoading} />
        </TableContainer>
    );
}
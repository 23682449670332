import { KeyboardArrowUp, Lock, Search } from "@mui/icons-material";
import { Badge, Box, Button, Container, Divider, Fab, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { deburr, remove } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import ScrollTop from "../../components/common/ScrollTop";
import ReturnableOrder from "../../components/order/ReturnableOrder";
import ReturnMaterial from "../../components/order/ReturnMaterial";
import { ReturnContext } from "../../contexts/ReturnContext";
import Order from "../../models/order/Order";
import OrderItem from "../../models/order/OrderItem";
import Return from "../../models/return/Return";
import { eqOrderUrl, eqRoomMaterialUrl } from "../../utils/BaseUrl";
import EmployeeLock from "../../models/EmployeeLock";

export default function ReturnBorrowed() {
    const { employeeId } = useParams();
    const [orders, setOrders] = useState<Order[]>([]);
    const [searchText, setSearchText] = useState('');
    const { changeTitle, snackbar, t } = useContext(LayoutContext);
    const returnContext = useContext(ReturnContext);

    const { data = [], refetch } = useQuery<Order[]>(["myOrders", employeeId], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order/employeeId/${employeeId}/unreturned`, signal), {
        onSuccess: data => setOrders(data)
    });

    useEffect(() => {
        changeTitle(t("ReturnBorrowed"));
        return () => { returnContext.clearState(); };
    }, []);

    const { data: employeeLock = { isEmployeeLocked: false } } = useQuery<EmployeeLock>(["employeeLock", employeeId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/cyclecount/employeeLock/${employeeId}`, signal));

    const { mutate: createReturn, isLoading: isCreatingReturn } = useMutation((req: Return) => fetchPost(`${eqOrderUrl}/v1/return`, req), {
        onSuccess: () => { snackbar({ message: t("Created"), severity: "success" }); returnContext.clearState(); refetch(); },
    });

    const requestSearch = (searchValue: string) => {
        const originalRows: Order[] = data;
        setSearchText(searchValue);
        if (!searchValue)
            return setOrders(originalRows);

        let filteredRows: OrderItem[] = [];

        originalRows.forEach((row: any) => {
            row.items.forEach((i: OrderItem) => {
                if (deburr(i.material!.partNumber.toLowerCase()).includes(deburr(searchValue.toLowerCase())) ||
                    deburr(i.material!.description.toLowerCase()).includes(deburr(searchValue.toLowerCase())))
                    filteredRows.push(i);
            });
        });

        let foundOrders: Order[] = [];
        filteredRows.forEach(row => {
            const foundOrder: Order | undefined = foundOrders.find(o => o.id === row.orderId);
            if (foundOrder) {
                remove(foundOrders, o => o.id === row.orderId);
                foundOrder.items = [...foundOrder.items, row];
                foundOrders.push(foundOrder);
            } else {
                const newOrder: Order = data.find(o => o.id === row.orderId)!;
                newOrder.items = [row];
                foundOrders.push(newOrder);
            }
        });

        setOrders(foundOrders);
    };

    if (employeeLock.isEmployeeLocked)
        return (
            <Box sx={{ position: "relative", height: { xs: "calc(100vh - 125px)", sm: "calc(100vh - 133px)" } }}>
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                    <Lock color="disabled" fontSize="large" />
                    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>{t("MaterialMovementIsSuspendedDuringYourInventory")}</Typography>
                </Box>
            </Box>
        );

    return (
        <Container sx={{ mt: 3 }} maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField placeholder={t("WhatAreYouLookingFor") + "?"} size="small" fullWidth value={searchText} onChange={e => requestSearch(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    {orders.map(order => <ReturnableOrder key={order.id} order={order} />)}
                </Grid>

                {!!returnContext.state.items?.length &&
                    <Grid item xs={12} sm={6}>
                        <Paper sx={{ p: 1 }}>
                            <Typography variant="h6"><b>{t("Return")}</b></Typography>
                            <Divider sx={{ mb: 2 }} />
                            {returnContext.state.items.map(item => <ReturnMaterial key={item.id} returnItem={item} />)}

                            <Button variant="contained" fullWidth onClick={() => createReturn(returnContext.state)} sx={{ mt: 2 }} disabled={!returnContext.state.items || isCreatingReturn}>{t("Return")}</Button>
                        </Paper>
                    </Grid>
                }
            </Grid>

            <ScrollTop>
                <Badge badgeContent={returnContext.state.items?.length} color="primary">
                    <Fab size="small">
                        <KeyboardArrowUp />
                    </Fab>
                </Badge>
            </ScrollTop>
        </Container>
    );
}
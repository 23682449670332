import { Add, ArrowBack, Check, Close, Done, Edit, FilterAlt, LocationOn, QrCode } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Box, Button, ButtonGroup, Chip, Divider, Fab, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography, styled } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import useSound from "use-sound";
import { LayoutContext } from "wcz-layout";
import { DialogScanner } from "wcz-scan";
import CycleCount from "../../../models/CycleCount";
import CycleCountDetail from "../../../models/CycleCountDetail";
import { CycleCountDetailStatus } from "../../../models/enums/CycleCountDetailStatus";
import { UpdateCycleCountDetail } from "../../../services/CycleCountService";
import { AddListItem } from "./AddListItem";

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

let quantityChangeTimeoutId: NodeJS.Timeout | null = null;

interface StartedCycleCountLocationProps {
    cycleCount: CycleCount;
    selectedLocatedAtId: string;
    setSelectedLocatedAtId: (selectedLocatedAtId: string) => void;
    refetch: () => void;
    groupedLocatedAtId: string[];
    finishCycleCount: () => void;
    anyWaiting: boolean;
    nextLocatedAt: string | undefined;
}

export const StartedCycleCountLocation: React.FC<StartedCycleCountLocationProps> = ({ cycleCount, selectedLocatedAtId, refetch, setSelectedLocatedAtId, groupedLocatedAtId, finishCycleCount, anyWaiting, nextLocatedAt }) => {
    const { t, snackbar } = useContext(LayoutContext);
    const [materialScannerOpen, setMaterialScannerOpen] = useState<boolean>(false);
    const [locatedAtScannerOpen, setLocatedAtScannerOpen] = useState<boolean>(false);
    const [selectedMaterial, setSelectedMaterial] = useState<string>('');
    const [addNewMaterialOpen, setAddNewMaterialOpen] = useState<boolean>(false);
    const [beep] = useSound(require('../../../utils/sounds/beep.mp3'));

    const openMaterialScanner = useCallback(() => setMaterialScannerOpen(true), []);

    const handleOnMaterialScan = useCallback((text: string) => {
        const foundSerialNumber = cycleCount.cycleCountDetails.find(d => d.serialNumber === text && d.partNumber === selectedMaterial);
        if (foundSerialNumber) {
            return updateAsync({ id: foundSerialNumber.id, cycleCountId: cycleCount.id, foundQuantity: 1, scanned: true }).then(() => beep());
        }

        const foundPartNumber = cycleCount.cycleCountDetails.find(d => d.partNumber === text);
        if (foundPartNumber) {
            setSelectedMaterial(text);
            beep();

            setTimeout(() => {
                const partNumberInput = document.getElementById(`${text}-input-qty`);
                if (partNumberInput)
                    return window.setTimeout(() => partNumberInput.focus(), 0);
            }, 0);
        } else {
            snackbar({ message: t("MaterialNotFound", { material: text }), severity: 'error' });
        }
    }, [cycleCount, selectedMaterial]);

    const openLocatedAtScanner = useCallback(() => setLocatedAtScannerOpen(true), []);

    const handleOnLocatedAtScan = useCallback((value: string) => {
        setSelectedMaterial('');

        if (value === nextLocatedAt) {
            beep();
            return setSelectedLocatedAtId(groupedLocatedAtId[groupedLocatedAtId.indexOf(selectedLocatedAtId) + 1]);
        }

        const locatedAt = cycleCount.cycleCountDetails.find(d => d.locatedAt === value);
        if (locatedAt) {
            beep();
            return setSelectedLocatedAtId(locatedAt.locatedAtId);
        }

        return snackbar({ message: t("LocationNotFound", { location: value }), severity: 'error' });
    }, [cycleCount, nextLocatedAt]);

    const { mutateAsync: updateAsync } = UpdateCycleCountDetail({ onSuccess: () => refetch() });

    const handleOnQuantityChange = useCallback((detail: CycleCountDetail) => (e: React.FocusEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (quantityChangeTimeoutId)
            clearTimeout(quantityChangeTimeoutId);

        quantityChangeTimeoutId = setTimeout(() => {
            updateAsync({ id: detail.id, cycleCountId: cycleCount.id, foundQuantity: value, scanned: selectedMaterial === detail.partNumber });
        }, 700);
    }, [cycleCount, selectedMaterial]);

    const handleOnSerialNumberChange = useCallback((detail: CycleCountDetail, value: number) => () => {
        updateAsync({ id: detail.id, cycleCountId: cycleCount.id, foundQuantity: value, scanned: false });
    }, [cycleCount]);

    const handleOnAddNewMaterial = useCallback(() => {
        setAddNewMaterialOpen(true);
        setSelectedMaterial("");
        window.scrollTo(0, 0);
    }, [cycleCount, selectedLocatedAtId]);

    const handleBackToLocations = useCallback(() => setSelectedLocatedAtId(''), []);

    const clearSelectedMaterial = useCallback(() => setSelectedMaterial(''), []);

    return (
        <Box sx={{ mt: 1, mb: 10 }}>
            <List subheader={<Typography variant="h6" gutterBottom onClick={handleBackToLocations} sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <ArrowBack sx={{ mr: 1.2 }} /> {cycleCount.cycleCountDetails.find(d => d.locatedAtId === selectedLocatedAtId)?.locatedAt}
            </Typography>}>

                {selectedMaterial && <Chip icon={<FilterAlt />} label={selectedMaterial} onDelete={clearSelectedMaterial} sx={{ ml: 1, my: 1 }} />}

                {addNewMaterialOpen && <AddListItem locationId={selectedLocatedAtId} locationName={cycleCount.cycleCountDetails.find(d => d.locatedAtId === selectedLocatedAtId)?.locatedAt} close={() => setAddNewMaterialOpen(false)} cycleCount={cycleCount} refetch={refetch} />}

                {cycleCount.cycleCountDetails.filter(d => d.locatedAtId === selectedLocatedAtId).filter(d => d.partNumber.includes(selectedMaterial)).map((value) =>
                    <Box id={value.partNumber} key={value.id}>
                        <ListItem secondaryAction={
                            value.serialNumber ?
                                <ButtonGroup>
                                    <Button color="success" variant={value.foundQuantity === 1 ? "contained" : "outlined"} onClick={handleOnSerialNumberChange(value, 1)}><Done /></Button>
                                    <Button color="error" variant={value.foundQuantity === 0 ? "contained" : "outlined"} onClick={handleOnSerialNumberChange(value, 0)}><Close /></Button>
                                </ButtonGroup>
                                :
                                <TextField id={`${value.partNumber}-input-qty`} size="small" defaultValue={value.foundQuantity} onChange={handleOnQuantityChange(value)} sx={{ width: '12.7ch' }} type="number" InputProps={{
                                    startAdornment: <InputAdornment position="start">Qty:</InputAdornment>,
                                }} />
                        }>

                            <ListItemIcon>
                                {value.status === CycleCountDetailStatus.Ok && <Done color="success" />}
                                {value.status === CycleCountDetailStatus.Updated && <Edit color="info" />}
                                {value.status === CycleCountDetailStatus.New && <Add color="warning" />}
                            </ListItemIcon>

                            <ListItemText primary={value.partNumber} secondary={value.serialNumber ? `SN: ${value.serialNumber}` : `${t("Quantity")}: ${value.expectedQuantity}`} />
                        </ListItem>
                        <Divider />
                    </Box>
                )}
            </List>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels>
                    <BottomNavigationAction label={t("AddMaterial")} icon={<Add />} sx={{ mr: 2 }} onClick={handleOnAddNewMaterial} disabled={!isEmpty(addNewMaterialOpen)} />
                    <StyledFab color="primary" onClick={openMaterialScanner}>
                        <QrCode />
                    </StyledFab>
                    {(anyWaiting && nextLocatedAt) ?
                        <BottomNavigationAction
                            label={t("NextLocation")}
                            icon={<LocationOn />}
                            sx={{ ml: 2 }}
                            onClick={openLocatedAtScanner}
                        />
                        :
                        <BottomNavigationAction
                            label={t("FinishCycleCount")}
                            icon={<Check color={anyWaiting ? "disabled" : undefined} />}
                            sx={{ ml: 2 }}
                            onClick={finishCycleCount}
                            disabled={anyWaiting} />
                    }
                </BottomNavigation>
            </Paper>

            <DialogScanner open={materialScannerOpen} setOpen={setMaterialScannerOpen} onResult={handleOnMaterialScan} title={t("ScanMaterial")!} />
            <DialogScanner open={locatedAtScannerOpen} setOpen={setLocatedAtScannerOpen} onResult={handleOnLocatedAtScan} title={`${t("ScanLocation")}: ${nextLocatedAt}`} />
        </Box>
    );
};
import { AddShoppingCart, ArrowCircleUp, Checklist, FactCheckOutlined, Loop, ProductionQuantityLimits, ShoppingCart, ShoppingCartCheckout } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutContext, fetchGet, hasRole } from 'wcz-layout';
import StyledCard from '../components/home/StyledCard';
import EmployeeLock from '../models/EmployeeLock';
import { eqOrderUrl, eqRoomMaterialUrl } from '../utils/BaseUrl';
import { whKeeperRole } from '../utils/UserRoles';

interface HomepageStats {
    purchasedOrders: number,
    returnOrders: number,
    shortageOrders: number
}

export default function HomePage() {
    const { changeTitle, user, t } = useContext(LayoutContext);
    const navigate = useNavigate();

    useEffect(() => changeTitle("Home"), [t]);

    const { data = {} as HomepageStats } = useQuery<HomepageStats>(["homepage"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order/homepage`, signal), {
        enabled: (hasRole(whKeeperRole)),
        initialData: { purchasedOrders: 0, returnOrders: 0, shortageOrders: 0 }
    });

    const { data: employeeLock = { isEmployeeLocked: false } } = useQuery<EmployeeLock>(["employeeLock", user.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/cyclecount/employeeLock/${user.id}`, signal));

    return (
        <React.Fragment>
            {(hasRole(whKeeperRole)) &&
                <Grid container spacing={1} sx={{ px: 1, mt: 1 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5">{t("Warehouse")}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <StyledCard title={t("MaterialReceiving")} onClick={() => navigate("/material/receive")}><ArrowCircleUp /></StyledCard>
                    <StyledCard title={`${t("PurchaseOrders")} (${data.purchasedOrders})`} onClick={() => navigate("/orders/type/purchase")}><AddShoppingCart /></StyledCard>
                    <StyledCard title={`${t("ReturnOrders")} (${data.returnOrders})`} onClick={() => navigate("/orders/type/return")}><ShoppingCartCheckout /></StyledCard>
                    <StyledCard title={`${t("ShortageOrders")} (${data.shortageOrders})`} onClick={() => navigate("/orders/type/shortage")}><ProductionQuantityLimits /></StyledCard>
                    <StyledCard title={t("MaterialTransfer")} onClick={() => navigate("/materialTransfer")}><FactCheckOutlined /></StyledCard>
                    <StyledCard title={t("SerialNumberTransfer")} onClick={() => navigate("/serialNumberTransfer")}><FactCheckOutlined /></StyledCard>
                </Grid>
            }

            <Grid container spacing={1} sx={{ px: 1, mt: 1 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">{t("User")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <StyledCard title={t("ReturnBorrowed")} onClick={() => navigate(`/return/${user.id}`)}><Loop /></StyledCard>
                <StyledCard title={t("Store")} onClick={() => navigate("/store")}><ShoppingCart /></StyledCard>
                {employeeLock.isEmployeeLocked && <StyledCard title={t("CycleCount")} onClick={() => navigate(`/cycle-count/self/${employeeLock.cycleCountId}`)}><Checklist /></StyledCard>}
            </Grid>
        </React.Fragment>
    );
}
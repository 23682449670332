import { Info } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { GridToolbar, TableContainer } from 'wcz-x-data-grid';
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, LayoutContext } from "wcz-layout";
import OrderDetails from "../../components/order/OrderDetails";
import Order from "../../models/order/Order";
import { OrderStatus } from "../../models/order/OrderStatus";
import { OrderType } from "../../models/order/OrderType";
import { eqOrderUrl } from "../../utils/BaseUrl";
import moment from "moment";

export default function Orders() {
    const [detailsOrderId, setDetailsOrderId] = useState<string>("");
    const { changeTitle, user, t } = useContext(LayoutContext);

    const { data: orders = [], isLoading } = useQuery<Order[]>(["orders"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order?employeeId=${user.id}`, signal), {
        enabled: !!user.id
    });

    useEffect(() => {
        changeTitle(t("Orders"));

        // eslint-disable-next-line
    }, []);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'actions', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem icon={<Info />} label="Details" onClick={() => setDetailsOrderId(params.id)} />,
            ],
        },
        { field: 'created', headerName: t("Created"), width: 150, maxWidth: 500, type: 'date', valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'customer', headerName: t("Customer"), width: 270, maxWidth: 500, },
        { field: 'status', headerName: "Status", width: 130, maxWidth: 500, type: 'singleSelect', valueOptions: Object.values(OrderStatus) },
        { field: 'type', headerName: t("Type"), width: 130, maxWidth: 500, type: 'singleSelect', valueOptions: Object.values(OrderType) },
        { field: 'remark', headerName: t("Remark"), width: 300, maxWidth: 500, },
        { field: 'modified', headerName: t("Modified"), width: 150, maxWidth: 500, hide: true, type: 'date', valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'modifiedBy', headerName: t("ModifiedBy"), width: 200, maxWidth: 500, hide: true, },
    ] as GridColDef[], [t]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={orders} columns={columns} components={{ Toolbar: GridToolbar }} loading={isLoading}
                    onCellDoubleClick={params => setDetailsOrderId(params.id as string)} />
            </TableContainer>

            <OrderDetails orderId={detailsOrderId} setOrderId={setDetailsOrderId} />
        </Fragment>
    );
}
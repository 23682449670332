import { Container, Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, LayoutContext } from "wcz-layout";
import ShortageOrder from "../../components/order/ShortageOrder";
import Order from "../../models/order/Order";
import { OrderStatus } from "../../models/order/OrderStatus";
import { OrderType } from "../../models/order/OrderType";
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function ShortageOrders() {
    const { changeTitle, t } = useContext(LayoutContext);

    const { data } = useQuery<Order[], Response>(["orders", "type", OrderType.Shortage], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order?type=${OrderType.Shortage}&status=${OrderStatus.Created}`, signal));

    useEffect(() => changeTitle(t("ShortageOrders")), []);

    return (
        <Grid container justifyContent="center">
            {data?.map(order =>
                <Grid item xs={11} key={order.id}>
                    <Container maxWidth="sm">
                        <ShortageOrder order={order} key={order.id}  />
                    </Container>
                </Grid>
            )}
        </Grid>
    );
}
import { DataGridPremium, GridColDef, GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { LayoutContext, fetchDelete, fetchGet, fetchPost, fetchPut } from "wcz-layout";
import { EditableHeader, GridDeleteCellItem, GridToolbar, TableContainer, editInputCellValidation } from 'wcz-x-data-grid';
import OrderAddressApiModel from "../models/order/OrderAddressApiModel";
import { OrderAddressType } from "../models/order/OrderAddressType";
import { eqOrderAddressUrl } from "../utils/BaseUrl";

export default function OrderAddresses() {
    const { changeTitle, t } = useContext(LayoutContext);

    const { data: orderAddresses = [] } = useQuery<OrderAddressApiModel[]>(["orderAddresses"], ({ signal }) => fetchGet(`${eqOrderAddressUrl}/v1/address`, signal));

    useEffect(() => changeTitle(t("OrderAddresses")), [t]);

    const create = useMutation((req: OrderAddressApiModel) => fetchPost(`${eqOrderAddressUrl}/v1/address`, req));
    const update = useMutation((req: OrderAddressApiModel) => fetchPut(`${eqOrderAddressUrl}/v1/address/${req.id}`, req));
    const remove = useMutation((id: GridRowId) => fetchDelete(`${eqOrderAddressUrl}/v1/address/${id}`));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridDeleteCellItem id={params.id} remove={remove} />
            ],
        },
        { field: 'name', headerName: t("Name"), width: 250, editable: true, renderHeader: EditableHeader, preProcessEditCellProps: editInputCellValidation.hasLength },
        {
            field: 'type', headerName: t("Type"), width: 200, editable: true, renderHeader: EditableHeader, preProcessEditCellProps: editInputCellValidation.hasLength,
            type: 'singleSelect', valueOptions: Object.values(OrderAddressType)
        },
        // eslint-disable-next-line
    ] as GridColDef[], [t]);

    const processRowUpdate = async (row: GridRowModel<OrderAddressApiModel>): Promise<OrderAddressApiModel> => {
        if (row.isNew)
            await create.mutateAsync(row);
        else
            await update.mutateAsync(row);

        return { ...row, isNew: false };
    };

    return (
        <TableContainer>
            <DataGridPremium rows={orderAddresses} columns={columns} components={{ Toolbar: GridToolbar }} editMode="row" processRowUpdate={processRowUpdate} />
        </TableContainer>
    );
}
import { Card, Divider, Typography } from "@mui/material";
import moment from "moment";
import { Fragment, useContext } from "react";
import { LayoutContext } from "wcz-layout";
import Order from "../../models/order/Order";
import MyOrderItem from "./myOrder/MyOrderItem";

interface ReturnableOrderProps {
    order: Order
}

export default function ReturnableOrder(props: ReturnableOrderProps) {
    const { t } = useContext(LayoutContext);

    return (
        <Card sx={{ p: 1, mb: 2 }}>
            <Typography variant="h6"><b>{t("Ordered")}:</b> {moment(props.order.created).formatDateTime()}</Typography>
            <Divider />
            {props.order.items.map((item, index, array) =>
                <Fragment>
                    <MyOrderItem item={item} />

                    {index + 1 !== array.length && <Divider />}
                </Fragment>
            )}
        </Card>
    );
}
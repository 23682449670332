import { Autocomplete, AutocompleteRenderInputParams, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { FileViewer, UploadFile } from "wcz-file";
import { fetchGet, LayoutContext } from "wcz-layout";
import { MaterialCategory } from "../../../models/enums/MaterialCategory";
import Material, { MaterialFilter } from "../../../models/Material";
import { eqRoomMaterialUrl } from "../../../utils/BaseUrl";
import MaterialModelUpdate from "../MaterialModelUpdate";

const textFieldProps: TextFieldProps = {
    variant: 'standard',
    fullWidth: true,
    size: 'small'
};

interface BaseFormProps {
    material: Material;
    setMaterial: (material: Material) => void;
}

export default function BaseForm(props: BaseFormProps) {
    const { material, setMaterial } = props;
    const { t } = useContext(LayoutContext);

    const { data: suggestions = {} as MaterialFilter, refetch: refetchSuggestions } = useQuery<MaterialFilter>(["materialFilter"], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/filter`, signal), {
        enabled: !!material.id,
    });

    const handleTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMaterial({ ...material, [e.target.name]: e.target.value }), [material]);

    const handleTextFieldNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setMaterial({ ...material, [e.target.name]: Number(e.target.value) }), [material]);

    const handleAutocompleteChange = useCallback((name: string) => (e: React.SyntheticEvent<Element | Event>, value: any) => setMaterial({ ...material, [name]: value }), [material]);

    const renderInput = useCallback((label: string, required?: boolean) => (params: AutocompleteRenderInputParams) =>
        <TextField {...params} {...textFieldProps} required={required} label={label} />, []);

    const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setMaterial({ ...material, [e.target.name]: e.target.checked }), [material]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
                <TextField {...textFieldProps} label="PN" name="partNumber" required value={material.partNumber ?? ""} onChange={handleTextFieldChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Autocomplete
                    value={material.category ?? null}
                    options={Object.values(MaterialCategory)}
                    autoHighlight
                    onChange={handleAutocompleteChange("category")}
                    renderInput={renderInput(t("Category"), true)}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Autocomplete
                    value={material.subCategory ?? null}
                    options={suggestions.subCategory}
                    getOptionLabel={option => typeof option === 'string' ? option : option.name}
                    autoHighlight
                    freeSolo
                    onChange={handleAutocompleteChange("subCategory")}
                    onInputChange={handleAutocompleteChange("subCategory")}
                    renderInput={renderInput(t("SubCategory"), true)}
                />
            </Grid>
            <Grid item xs={12} lg={8}>
                <TextField {...textFieldProps} label={t("Description")} name="description" required value={material.description ?? ""} onChange={handleTextFieldChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Autocomplete
                    value={material.type ?? ""}
                    options={suggestions.type}
                    autoHighlight
                    freeSolo
                    onChange={handleAutocompleteChange("type")}
                    onInputChange={handleAutocompleteChange("type")}
                    renderInput={renderInput(t("Type"))}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                {material.isNew ?
                    <Autocomplete
                        value={material.models ?? []}
                        options={suggestions.models}
                        getOptionLabel={option => typeof option === 'string' ? option : option.name}
                        autoHighlight
                        freeSolo
                        multiple
                        onChange={handleAutocompleteChange("models")}
                        renderInput={renderInput(t("Models"))}
                    />
                    :
                    <MaterialModelUpdate material={material} setMaterial={setMaterial} suggestions={suggestions} refetchSuggestions={refetchSuggestions} />
                }
            </Grid>
            <Grid item xs={12} lg={4}>
                <Autocomplete
                    value={material.vendor ?? ""}
                    options={suggestions.vendor}
                    autoHighlight
                    freeSolo
                    onChange={handleAutocompleteChange("vendor")}
                    onInputChange={handleAutocompleteChange("vendor")}
                    renderInput={renderInput(t("Vendor"), true)}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <TextField {...textFieldProps} label={`${t("Vendor")} PN`} name="vendorPartNumber" required value={material.vendorPartNumber ?? ""} onChange={handleTextFieldChange} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Autocomplete
                    value={material.project ?? ""}
                    options={suggestions.projects}
                    autoHighlight
                    freeSolo
                    onChange={handleAutocompleteChange("project")}
                    renderInput={renderInput(t("Project"))}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <TextField {...textFieldProps} label={t("UnitPrice")} name="unitPrice" required value={material.unitPrice ?? ""} onChange={handleTextFieldNumberChange} type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">CZK</InputAdornment> }} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <TextField {...textFieldProps} label={t("SafetyStock")} name="safetyStock" required value={material.safetyStock ?? ""} onChange={handleTextFieldChange} type="number" />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField {...textFieldProps} label={t("Remark")} name="remark" value={material.remark ?? ""} onChange={handleTextFieldChange} />
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox name="isReturnable" checked={material.isReturnable ?? false} onChange={handleCheckboxChange} size="small" />} label={t("IsReturnable")} />
                        <FormControlLabel control={<Checkbox name="hasItems" checked={material.hasItems ?? false} onChange={handleCheckboxChange} size="small" />} label={t("HasSn")} />
                        <FormControlLabel control={<Checkbox name="exchange" checked={material.exchange ?? false} onChange={handleCheckboxChange} size="small" />} label={t("Exchange")} />
                    </FormGroup>
                </FormControl>
            </Grid>
            {material.id &&
                <Grid item xs={12}>
                    <UploadFile subId={material.id} />
                    <br />
                    <FileViewer subId={material.id} />
                </Grid>
            }
        </Grid>
    );
}
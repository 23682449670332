import { Autocomplete, TextField, Typography } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import Item from "../../../models/Item";
import Location from "../../../models/Location";
import Return from "../../../models/return/Return";
import ReturnItem from "../../../models/return/ReturnItem";
import ReturnItemMaterialItem from "../../../models/return/ReturnItemMaterialItem";
import { eqOrderUrl, eqRoomMaterialUrl, eqRoomUrl } from "../../../utils/BaseUrl";

interface ReturnOrderItemMaterialItemProps {
    materialItem: ReturnItemMaterialItem,
    returnItem: ReturnItem,
    locations: Location[],
    returnOrder: Return,
    scrap: Location | undefined,
    hold: Location | undefined
}

export default function ReturnOrderItemMaterialItem(props: ReturnOrderItemMaterialItemProps) {
    const { locations, hold, scrap } = props;
    const [location, setLocation] = useState<Location | null>(null);
    const { t } = useContext(LayoutContext);

    const { data: item = {} as Item } = useQuery<Item>(["item", props.materialItem.itemId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/${props.materialItem.itemId}`, signal), {
        enabled: !!props.materialItem.itemId,
    });

    useQuery<Location>(["location", props.materialItem.locationId], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location/${props.materialItem.locationId}`, signal), {
        enabled: !!props.materialItem.locationId,
        onSuccess: data => setLocation(data)
    });

    const { mutate } = useMutation((req: Return) => fetchPut(eqOrderUrl + "/v1/return/" + req.id, req));

    const { mutate: addLocation } = useMutation((req: { materialId: string, locationId: string }) => fetchPost(`${eqRoomUrl}/v1/material`, req), {
        onSuccess: (data, variables) => {
            const foundLocation: Location = locations.find(l => l.id === variables.locationId)!;
            updateReturn(foundLocation);
        }
    });

    const handleOnLocationChange = (newLocation: Location | null) => {
        if (!newLocation)
            return;

        setLocation(newLocation);

        if (newLocation.name === "SCRAP")
            return addLocation({ materialId: props.returnItem.materialId, locationId: newLocation.id });

        if (newLocation.name === "HOLD")
            return addLocation({ materialId: props.returnItem.materialId, locationId: newLocation.id });

        updateReturn(newLocation);
    };

    const updateReturn = (newLocation: Location) => {
        const request: Return = props.returnOrder;
        const returnItemIndex = request.items.findIndex(i => i.id === props.returnItem.id);
        const materialItemIndex = request.items[returnItemIndex].materialItems.findIndex(mi => mi.id === props.materialItem.id);

        request.items[returnItemIndex].materialItems[materialItemIndex].locationId = newLocation.id;

        mutate(request);
    };

    const getLocations = (): Location[] => {
        let options: Location[] = locations;

        if (!options.find(l => l.name === "SCRAP") && scrap)
            options.push(scrap);

        if (!options.find(l => l.name === "HOLD") && hold)
            options.push(hold);

        return options;
    };

    return (
        <Fragment>
            <Typography variant="subtitle2" color="text.secondary"><b>SN: </b>{item.serialNumber}</Typography>

            <Autocomplete
                value={location}
                options={getLocations()}
                getOptionLabel={l => l.name}
                onChange={(e, value) => handleOnLocationChange(value)}
                autoHighlight
                sx={{ mt: 1 }}
                renderInput={(params) => <TextField {...params} label={t("Location")} multiline size="small" sx={{ mb: 1 }} />}
            />
        </Fragment>
    );
}
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import { GridToolbar, TableContainer } from "wcz-x-data-grid";
import ToolingPart from "../../models/ToolingPart";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";
import CustomDialog from "../common/CustomDialog";

interface ToolingPartDetailProps {
    toolingPartId: string,
    setToolingPartId: (toolingPartId: string) => void
}

export default function ToolingPartDetail(props: ToolingPartDetailProps) {
    const { toolingPartId, setToolingPartId } = props;
    const { t } = useContext(LayoutContext);

    const { data: toolingPart = {} as ToolingPart } = useQuery<ToolingPart>(["toolingPart", toolingPartId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/toolingpart/${toolingPartId}`, signal), {
        enabled: !!toolingPartId
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'partNumber', headerName: "PN", width: 220 },
        { field: 'subCategory', headerName: t("SubCategory"), width: 150, valueGetter: ({ value }) => value?.name, },
        { field: 'description', headerName: t("Description"), width: 400, },
        { field: 'usableQuantity', headerName: t("UsableQuantity"), width: 170, type: 'number', },
    ] as GridColDef[], []);

    const handleOnClose = useCallback(() => setToolingPartId(""), []);

    return (
        <CustomDialog open={!!toolingPartId} onClose={handleOnClose} title={`${toolingPart.subCategory?.name}, ${toolingPart.model?.name}`} maxWidth="lg" disablePadding color="primary">
            <TableContainer height={500}>
                <DataGridPremium rows={toolingPart.materialList ?? []} columns={columns} components={{ Toolbar: GridToolbar }} />
            </TableContainer>
        </CustomDialog>
    );
}
import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchGet } from 'wcz-layout';
import Item from '../../models/Item';
import Material from '../../models/Material';
import { eqRoomMaterialUrl } from '../../utils/BaseUrl';
import MaterialReceivingLocationCard from './materialReceivingCard/MaterialReceivingLocationCard';

interface MaterialReceivingCardProps {
    materialId: string
}

export default function MaterialReceivingCard(props: MaterialReceivingCardProps) {

    const { data: material = {} as Material, refetch: refetchMaterial } = useQuery<Material>(["material", props.materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${props.materialId}`, signal), {
        enabled: !!props.materialId
    });

    const { data: materialItems = [], refetch: refetchMaterialItems } = useQuery<Item[]>(["item", "materialId", material.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/material/${material.id}`, signal), {
        enabled: material.hasItems === true
    });

    const refetch = useCallback(() => {
        setTimeout(() => {
            refetchMaterial();
            refetchMaterialItems();
        }, 300);
    }, []);

    return (
        <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12}>
                <Typography variant="h6">{material.vendor}</Typography>
            </Grid>

            <Grid item xs={12}>
                {material.locations?.filter(l => l.name !== "SCRAP" && l.name !== "HOLD")
                    .map(location => <MaterialReceivingLocationCard materialLocation={location} material={material} materialItems={materialItems} refetch={refetch} key={location.id} />)}
            </Grid>
        </Grid>
    );
}
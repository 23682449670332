import { createContext, useContext, useEffect, useState } from 'react';
import { LayoutContext, newGuid } from 'wcz-layout';
import Return from '../models/return/Return';
import ReturnItem from '../models/return/ReturnItem';

interface ReturnContextInterface {
    state: Return,
    clearState: () => void,
    addMaterial: (quantity: number, orderItemId: string, materialId: string) => void,
    removeMaterial: (orderItemId: string) => void,
    addItem: (itemId: string, orderItemId: string, materialId: string) => void,
    removeItem: (itemId: string) => void,
    updateItemRemark: (id: string, value: string) => void,
    removeReturnOrderItem: RemoveReturnOrderItem,
    setRemoveReturnOrderItem: (removeReturnOrderItem: RemoveReturnOrderItem) => void,
}

export const ReturnContext = createContext({} as ReturnContextInterface);

interface ReturnProviderProps {
    children?: React.ReactNode
}

export interface RemoveReturnOrderItem {
    orderItemId?: string,
    itemId?: string
}

export function ReturnProvider(props: ReturnProviderProps) {
    const [state, setState] = useState({} as Return);
    const [removeReturnOrderItem, setRemoveReturnOrderItem] = useState<RemoveReturnOrderItem>({} as RemoveReturnOrderItem);
    const { user } = useContext(LayoutContext);

    useEffect(() => {
        if (user.name)
            setInitialState();
        // eslint-disable-next-line
    }, [user.name]);

    const setInitialState = () => {
        setState({
            ...state,
            items: [],
            isConfirmed: false,
            returningUser: `${user.name} (${user.id})`
        });
    };

    const addMaterial = (quantity: number, orderItemId: string, materialId: string) => {
        const returnItems: ReturnItem[] = state.items ?? [];

        const returnItemWithSameOrderItemId: ReturnItem | undefined = returnItems.find(returnItem => returnItem.orderItemId === orderItemId);
        if (returnItemWithSameOrderItemId) {
            returnItemWithSameOrderItemId.quantity = returnItemWithSameOrderItemId.quantity + quantity;
        } else {
            returnItems.push({
                id: newGuid(),
                orderItemId: orderItemId,
                quantity: quantity,
                returnId: "",
                materialItems: [],
                materialId: materialId,
                locations: []
            });
        }

        setState({ ...state, items: returnItems });
    };

    const removeMaterial = (orderItemId: string) => {
        const returnItems: ReturnItem[] = state.items ?? [];

        let updatedItems = returnItems.map(returnItem => {
            if (returnItem.orderItemId === orderItemId) {
                returnItem.quantity = returnItem.quantity - 1;
                setRemoveReturnOrderItem({ orderItemId: orderItemId });
            }
            return returnItem;
        });

        updatedItems = updatedItems.filter(returnItem => returnItem.quantity > 0);

        setState({ ...state, items: updatedItems });
    };

    const addItem = (itemId: string, orderItemId: string, materialId: string) => {
        const returnItems: ReturnItem[] = state.items ?? [];

        const returnItemWithSameOrderItemId: ReturnItem | undefined = returnItems.find(returnItem => returnItem.orderItemId === orderItemId);
        if (returnItemWithSameOrderItemId) {
            returnItemWithSameOrderItemId.quantity = returnItemWithSameOrderItemId.quantity + 1;

            returnItemWithSameOrderItemId.materialItems.push({ id: newGuid(), itemId: itemId, returnItemId: orderItemId });
        } else {
            returnItems.push({
                id: newGuid(),
                orderItemId: orderItemId,
                quantity: 1,
                returnId: "",
                materialItems: [{
                    itemId: itemId,
                    returnItemId: ""
                }],
                materialId: materialId,
                locations: []
            });
        }

        setState({ ...state, items: returnItems });
    };

    const removeItem = (itemId: string) => {
        setRemoveReturnOrderItem({ itemId: itemId });
        
        const returnItems: ReturnItem[] = state.items ?? [];
        
        let updatedItems = returnItems.map(returnItem => {
            returnItem.materialItems = returnItem.materialItems.filter(materialItem => materialItem.itemId !== itemId);
            returnItem.quantity = returnItem.materialItems.length;
            return returnItem;
        });

        updatedItems = updatedItems.filter(returnItem => returnItem.quantity > 0);

        setState({ ...state, items: updatedItems });
    };

    const updateItemRemark = (id: string, value: string) => {
        const updatedItems: ReturnItem[] = state.items.map(item => {
            if (item.id === id) {
                const updatedItem: ReturnItem = { ...item, remark: value };
                return updatedItem;
            }
            return item;
        });

        setState({ ...state, items: updatedItems });
    };

    return <ReturnContext.Provider value={{
        state: state, addMaterial: addMaterial, removeMaterial: removeMaterial, addItem: addItem, removeItem: removeItem, updateItemRemark: updateItemRemark, clearState: () => setInitialState(),
        removeReturnOrderItem: removeReturnOrderItem, setRemoveReturnOrderItem: setRemoveReturnOrderItem
    }}>
        {props.children}
    </ReturnContext.Provider>;
}
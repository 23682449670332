import { Collapse, TextField } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import { fetchPut, LayoutContext } from "wcz-layout";
import Order from '../../../models/order/Order';
import { eqOrderUrl } from "../../../utils/BaseUrl";

const REMARK_LENGTH: number = 500;

interface WarehouseOrderCardRemarkProps {
    open: boolean,
    order: Order
}

export default function WarehouseOrderCardRemark(props: WarehouseOrderCardRemarkProps) {
    const [inputValue, setInputValue] = useState("");
    const { t } = useContext(LayoutContext);

    useEffect(() => {
        if (props.order.remark)
            setInputValue(props.order.remark);
    }, [props.order.remark]);

    const { mutate } = useMutation((req: any) => fetchPut(eqOrderUrl + "/v1/order/" + req.id, req));

    const handleOnBlur = () => {
        mutate({
            id: props.order.id as string,
            remark: inputValue
        });
    };

    return (
        <Collapse in={props.open} timeout="auto" unmountOnExit sx={{ p: 1 }}>
            <TextField label={t("Remark")} value={inputValue} onChange={e => setInputValue(e.target.value)} fullWidth size="small" onBlur={handleOnBlur} multiline
                error={inputValue.length > REMARK_LENGTH} helperText={inputValue.length > REMARK_LENGTH && `${inputValue.length}/${REMARK_LENGTH}`} />
        </Collapse>
    );
}
import { Refresh } from '@mui/icons-material';
import { Fab, Typography } from '@mui/material';
import React from 'react';
import { useScrollDirection } from '../../utils/UseScrollDirection';

interface RefetchButtonProps {
    onClick: () => void;
    value: string;
}

const RefetchButton: React.FC<RefetchButtonProps> = ({ onClick, value }) => {
    const scrollDirection = useScrollDirection();

    return (
        <Fab
            variant="extended"
            size="small"
            onClick={onClick}
            sx={{ display: scrollDirection === 'up' ? 'block' : 'none', position: 'fixed', bottom: 10, left: "50%" }}>
            <Typography sx={{ display: 'flex', alignItems: 'center', }}>
                <Refresh fontSize="small" sx={{ mr: 1, mb: 0.3 }} color="disabled" />{value}
            </Typography>
        </Fab>
    );
};

export default RefetchButton;

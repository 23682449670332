import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium";
import { Filter } from "../models/base/PaginationFilter";

export const getOrderBy = (model: GridSortModel | undefined): string[] | undefined => {
    if (!model)
        return undefined;

    return model.map(model => `${getField(model.field)} ${model.sort}`);
};

export const getAdvancedFilter = (model: GridFilterModel | undefined) => {
    if (!model)
        return undefined;

    const filters: Filter[] = model.items.map(item => ({
        field: getField(item.field),
        value: item.value,
        operator: getServerSideOperator(item.operator),
    }));

    if (filters.length)
        return { logic: model.logicOperator, filters: filters };
};

const getField = (columnField: string): string => {
    switch (columnField) {
        case "subCategory": return "subCategory.name";
        case "models": return "models.name";
        case "locations": return "locations.name";
        default: return columnField;
    }
};

export const getKeyword = (values: any[] | undefined): string | undefined => {
    if (!values?.length)
        return undefined;

    return values.join(" ");
};

const getServerSideOperator = (value: string | undefined) => {
    switch (value) {
        case "=": return "eq";
        case "equals": return "eq";
        case "is": return "eq";
        case "!=": return "neq";
        case "<": return "lt";
        case "<=": return "lte";
        case ">": return "gt";
        case ">=": return "gte";
        case "startsWith": return "startswith";
        case "endsWith": return "endswith";
        default: return value;
    }
};

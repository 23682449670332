import { Check } from "@mui/icons-material";
import { Alert, Box, Button, Menu, Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import CycleCount from "../../models/CycleCount";
import { useStartCycleCount } from "../../services/CycleCountService";

interface NewCycleCountProps {
    cycleCount: CycleCount;
    refetch: () => void;

}
export const NewCycleCount: React.FC<NewCycleCountProps> = ({ cycleCount, refetch }) => {
    const { t, i18n } = useContext(LayoutContext);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'locatedAt', headerName: t("LocatedAt"), width: 220, },
        { field: 'partNumber', headerName: "PN", width: 170, },
        { field: 'serialNumber', headerName: "SN", width: 170, },
        { field: 'expectedQuantity', headerName: t("ExpectedQuantity"), width: 140, type: 'number', },
    ] as GridColDef[], [i18n.language, cycleCount]);

    const { mutate, isLoading: isStarting } = useStartCycleCount({ onSuccess: () => refetch() });

    const start = useCallback(() => mutate(cycleCount.id), [cycleCount]);

    const openContextMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setContextMenu({ mouseX: e.clientX, mouseY: e.clientY, });
    }, []);

    const closeContextMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => setContextMenu(null), []);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPremium rows={cycleCount.cycleCountDetails ?? []} columns={columns} components={{ Toolbar: GridToolbar }}
                    editMode="cell" componentsProps={{
                        toolbar: {
                            items: [{ title: t('Start'), onClick: openContextMenu, icon: <Check /> }]
                        } as GridToolbarProps
                    }} />
            </TableContainer>

            <Menu open={contextMenu !== null} onClose={closeContextMenu} anchorReference="anchorPosition" variant="menu"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box sx={theme => ({ width: { xs: 300, sm: 320, md: 360, lg: 400, xl: 440 }, px: 2 })} role="presentation">
                    <Alert severity="warning" sx={{ my: 2, mt: 1 }}>
                        <Typography variant="body2">{t("CycleCountStartInfo")}</Typography>
                    </Alert>

                    <Button variant="contained" fullWidth onClick={start} disabled={isStarting}>{t("Start")} {cycleCount.name}</Button>
                </Box>
            </Menu>
        </Fragment>
    );
};
import { Search, Storage } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridCellParams, GridColDef, GridFilterModel, GridPaginationModel, GridRowModel, GridSortModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchGet, fetchPost, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import { AutocompleteEditInputCell, EditableHeader, TableContainer } from 'wcz-x-data-grid';
import ToolingPartDetail from "../components/toolingParts/ToolingPartDetail";
import { ToolingPartGridToolbar } from "../components/toolingParts/ToolingPartGridToolbar";
import PaginationFilter from "../models/base/PaginationFilter";
import PaginationResponse from "../models/base/PaginationResponse";
import { MaterialFilter } from "../models/Material";
import ToolingPart from "../models/ToolingPart";
import { eqRoomMaterialUrl } from "../utils/BaseUrl";
import { getAdvancedFilter, getKeyword, getOrderBy } from "../utils/ServerSide";
import { adminRole, whKeeperRole } from "../utils/UserRoles";

export default function ToolingParts() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);
    const [serverSide, setServerSide] = useState<PaginationFilter>({ pageNumber: 0, pageSize: 100 });
    const [detailId, setDetailId] = useState<string>("");

    const { data = {} as PaginationResponse<ToolingPart>, isFetching, refetch } = useQuery<PaginationResponse<ToolingPart>>(["toolingparts", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/toolingpart/search`, serverSide), {
        enabled: !!serverSide
    });

    const { data: suggestions = {} as MaterialFilter } = useQuery<MaterialFilter>(["materialFilter"], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/filter`, signal));

    useEffect(() => changeTitle("ToolingParts"), []);

    const create = useMutation((req: ToolingPart) => fetchPost(`${eqRoomMaterialUrl}/v1/toolingpart`, req));
    const update = useMutation((req: ToolingPart) => fetchPut(`${eqRoomMaterialUrl}/v1/toolingpart/${req.id}`, req));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridActionsCellItem key="detail" label={t("Material")} icon={<Storage />} onClick={handleDetailClick(params.id)} showInMenu />,
                <GridActionsCellItem key="alternative" label="Find alternative" icon={<Search />} onClick={handleDetailClick(params.id)} showInMenu disabled />,
            ],
        },
        {
            field: 'subCategoryName', headerName: t("SubCategory"), width: 220, editable: true, renderHeader: EditableHeader, sortable: false,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={suggestions.subCategory.map(s => s.name)} freeSolo />
        },
        {
            field: 'modelName', headerName: t("Model"), width: 220, editable: true, renderHeader: EditableHeader, sortable: false,
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={suggestions.models.map(m => m.name)} freeSolo />
        },
        { field: 'usableQuantity', headerName: t("UsableQuantity"), width: 180, type: 'number', },
        { field: 'totalQuantity', headerName: t("TotalQuantity"), width: 180, type: 'number', },
        { field: 'safetyStock', headerName: t("SafetyStock"), width: 180, type: 'number', editable: true, renderHeader: EditableHeader },
        { field: 'orderDate', headerName: t("OrderDate"), width: 170, type: 'date', editable: true, renderHeader: EditableHeader, valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'remark', headerName: t("Remark"), width: 350, editable: true, renderHeader: EditableHeader },
    ] as GridColDef[], [suggestions, i18n.language]);

    const handleDetailClick = useCallback((id: string) => () => setDetailId(id), []);

    const processRowUpdate = async (row: GridRowModel): Promise<ToolingPart> => {
        const trimmedSubCategory = row.subCategoryName?.trim();
        let subCategory = suggestions.subCategory.find(s => s.name === trimmedSubCategory);
        if (!subCategory)
            subCategory = { name: trimmedSubCategory };

        const trimmedModel = row.modelName?.trim();
        let model = suggestions.models.find(m => m.name === trimmedModel);
        if (!model)
            model = { name: trimmedModel };

        const updatedRow: ToolingPart = {
            ...row as ToolingPart,
            subCategory: subCategory,
            model: model
        };

        if (updatedRow.isNew) {
            await create.mutateAsync(updatedRow);
            if (create.isError) {
                return { ...updatedRow, isNew: true };
            }
        }
        else {
            await update.mutateAsync(updatedRow);
        }

        return { ...updatedRow, isNew: false };
    };

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setServerSide({
            ...serverSide,
            keyword: getKeyword(filterModel.quickFilterValues),
            advancedFilter: getAdvancedFilter(filterModel)
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setServerSide({
            ...serverSide,
            orderBy: getOrderBy(sortModel)
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handlePaginationModelChange = useCallback((model: GridPaginationModel) => {
        setServerSide({
            ...serverSide,
            pageNumber: model.page,
            pageSize: model.pageSize
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const isCellEditable = useCallback((params: GridCellParams): boolean => {
        switch (params.field) {
            case "subCategoryName": return params.row.isNew;
            case "modelName": return params.row.isNew;
            default: return true;
        }
    }, []);

    if (hasRole(whKeeperRole) || hasRole(adminRole))
        return (
            <Fragment>
                <TableContainer>
                    <DataGridPremium rows={data.content ?? []} columns={columns} components={{ Toolbar: ToolingPartGridToolbar }} editMode="row" isCellEditable={isCellEditable}
                        processRowUpdate={processRowUpdate} loading={isFetching}
                        filterMode="server" onFilterModelChange={onFilterChange}
                        sortingMode="server" onSortModelChange={handleSortModelChange}
                        pagination paginationModel={{ page: serverSide.pageNumber ?? 0, pageSize: serverSide.pageSize ?? 100 }} paginationMode="server" onPaginationModelChange={handlePaginationModelChange} rowCount={data?.totalElements ?? 0} pageSizeOptions={[]}
                    />
                </TableContainer>

                <ToolingPartDetail toolingPartId={detailId} setToolingPartId={setDetailId} />
            </Fragment>
        );

    return <h5>Unauthorized</h5>;
}
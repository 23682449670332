import { DataGridPremium, GridColDef, GridFilterModel, GridPaginationModel, GridRowId, GridRowModel, GridSortModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchDelete, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import { EditableHeader, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import PaginationFilter from "../models/base/PaginationFilter";
import PaginationResponse from "../models/base/PaginationResponse";
import { MaterialCategory } from "../models/enums/MaterialCategory";
import Item from "../models/Item";
import { eqRoomMaterialUrl } from "../utils/BaseUrl";
import { getAdvancedFilter, getKeyword, getOrderBy } from "../utils/ServerSide";

export default function Items() {
    const [items, setItems] = useState<Item[]>([]);
    const [serverSide, setServerSide] = useState<PaginationFilter>({ pageNumber: 0, pageSize: 100 });
    const { changeTitle, user, t } = useContext(LayoutContext);

    const { data, isFetching, refetch } = useQuery<PaginationResponse<Item>>(["items", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/item/search`, serverSide), {
        enabled: !!serverSide,
        onSuccess: data => {
            setItems(data.content);
        }
    });

    useEffect(() => changeTitle("SN"), []);

    const update = useMutation((req: Item) => fetchPut(`${eqRoomMaterialUrl}/v1/item/${req.id}`, req));
    const remove = useMutation((id: GridRowId) => fetchDelete(`${eqRoomMaterialUrl}/v1/item/${id}`));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridDeleteCellItem id={params.id} remove={remove} />
            ],
        },
        { field: 'serialNumber', headerName: "SN", width: 220, editable: user.id === "CS1209003" || user.id === "C1811007" || user.id === "CS1101297", },
        { field: 'materialPartNumber', headerName: "PN", width: 220, },
        { field: 'materialCategory', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory), sortable: false, },
        { field: 'location', headerName: t("Location"), width: 180, valueGetter: ({ value }) => value?.name, sortable: false, },
        { field: 'keeper', headerName: t("Keeper"), width: 220, },
        { field: 'vendorSerialNumber', headerName: `${t("Vendor")} SN`, width: 180, editable: true, renderHeader: EditableHeader, },
        { field: 'vendorPartNumber', headerName: `${t("Vendor")} PN`, width: 180, editable: true, renderHeader: EditableHeader, },
        { field: 'remark', headerName: t("Remark"), width: 350, editable: true, renderHeader: EditableHeader, },
    ] as GridColDef[], [user, t]);

    const processRowUpdate = async (row: GridRowModel<Item>): Promise<Item> => {
        await update.mutateAsync(row);
        return { ...row };
    };

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setServerSide({
            ...serverSide,
            keyword: getKeyword(filterModel.quickFilterValues),
            advancedFilter: getAdvancedFilter(filterModel)
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setServerSide({
            ...serverSide,
            orderBy: getOrderBy(sortModel)
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handlePaginationModelChange = useCallback((model: GridPaginationModel) => {
        setServerSide({
            ...serverSide,
            pageNumber: model.page,
            pageSize: model.pageSize
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    return (
        <TableContainer>
            <DataGridPremium rows={items} columns={columns} components={{ Toolbar: GridToolbar }} editMode="row"
                componentsProps={{ toolbar: { hideAddRecord: true } as GridToolbarProps }} processRowUpdate={processRowUpdate} loading={isFetching}
                filterMode="server" onFilterModelChange={onFilterChange}
                sortingMode="server" onSortModelChange={handleSortModelChange}
                pagination paginationModel={{ page: serverSide.pageNumber ?? 0, pageSize: serverSide.pageSize ?? 100 }} paginationMode="server" onPaginationModelChange={handlePaginationModelChange} rowCount={data?.totalElements ?? 0} pageSizeOptions={[]}            />
        </TableContainer>
    );
}
import { Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import Material from "../../../models/Material";
import OrderItem from "../../../models/order/OrderItem";
import { eqRoomMaterialUrl } from "../../../utils/BaseUrl";

interface ShortageOrderItemProps {
    item: OrderItem,
}

export default function ShortageOrderItem(props: ShortageOrderItemProps) {
    const { item } = props;
    const { t } = useContext(LayoutContext);

    const { data: material } = useQuery<Material>(["material", item.materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${item.materialId}`, signal), {
        enabled: !!item.materialId,
    });

    return (
        <Grid container key={item.id} sx={{ my: 3 }}>
            <Grid item xs={9}>
                <Typography>{material?.partNumber}</Typography>
                <Typography>{material?.description}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                <Typography>{item.quantity} {String(t("Pcs")).toLowerCase()}</Typography>
            </Grid>
        </Grid>
    );
}
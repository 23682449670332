import { Button, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, LayoutContext } from "wcz-layout";
import { ReturnContext } from "../../../../contexts/ReturnContext";
import Item from "../../../../models/Item";
import OrderItemMaterialItem from "../../../../models/order/OrdetItemMaterialItem";
import { eqRoomMaterialUrl } from "../../../../utils/BaseUrl";

interface MyOrderItemMaterialItemProps {
    materialItem: OrderItemMaterialItem
}

export default function MyOrderItemMaterialItem(props: MyOrderItemMaterialItemProps) {
    const returnContext = useContext(ReturnContext);
    const [isReturned, setIsReturned] = useState(false);
    const { t } = useContext(LayoutContext);

    const { data: item = {} as Item } = useQuery<Item, Response>(["item", props.materialItem.itemId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/${props.materialItem.itemId}`, signal), {
        enabled: !!props.materialItem.itemId,
    });

    useEffect(() => {
        if (returnContext.removeReturnOrderItem.itemId === item.id) {       
            setIsReturned(false);
        }
        
    }, [returnContext.removeReturnOrderItem]);

    const handleOnReturnClick = () => {
        returnContext.addItem(props.materialItem.itemId, props.materialItem.orderItemId, item?.materialId);
        setIsReturned(true);
    };

    if (isEmpty(item) || isReturned)
        return null;

    return (
        <Fragment>
            <Typography variant="subtitle2" color="text.secondary"><b>SN: </b>{item.serialNumber}</Typography>
            <Button variant="contained" onClick={handleOnReturnClick} sx={{ textAlignt: 'right' }}>{t("Return")}</Button>
        </Fragment>
    );
}
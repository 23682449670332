import { useQuery } from "@tanstack/react-query";
import Material from "../../models/Material";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";
import { fetchGet } from "wcz-layout";
import { Fragment } from "react";
import { Typography } from "@mui/material";

interface SelfCycleCountMaterialDescriptionProps {
    materialId: string;
}

export const SelfCycleCountMaterialDescription: React.FC<SelfCycleCountMaterialDescriptionProps> = ({ materialId }) => {

    const { data: material = {} as Material } = useQuery<Material>(["material", materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${materialId}`, signal), {
        enabled: !!materialId,
    });

    return (
        <Fragment>
            <Typography>{material.description}</Typography>
        </Fragment>
    );
};
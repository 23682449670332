import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutContext, hasRole } from 'wcz-layout';
import { FinishedCycleCount } from '../components/cycleCount/FinishedCycleCount';
import { NewCycleCount } from '../components/cycleCount/NewCycleCount';
import { StartedCycleCount } from '../components/cycleCount/StartedCycleCount';
import CycleCount from '../models/CycleCount';
import { CycleCountStatus } from '../models/enums/CycleCountStatus';
import { useGetCycleCount } from '../services/CycleCountService';
import { whKeeperRole } from '../utils/UserRoles';

export default function CycleCountPage() {
    const { id } = useParams();
    const { t, changeTitle } = useContext(LayoutContext);

    const { data: cycleCount = {} as CycleCount, refetch } = useGetCycleCount(id, {
        onSuccess: data => changeTitle(t(data.name ? data.name : "CycleCount"))
    });

    if (!hasRole(whKeeperRole))
        return null;

    if (cycleCount.status === CycleCountStatus.New)
        return <NewCycleCount cycleCount={cycleCount} refetch={refetch} />;

    if (cycleCount.status === CycleCountStatus.Started)
        return <StartedCycleCount cycleCount={cycleCount} refetch={refetch} />;

    if (cycleCount.status === CycleCountStatus.Finished || cycleCount.status === CycleCountStatus.Confirmed)
        return <FinishedCycleCount cycleCount={cycleCount} refetch={refetch} />;

    return null;
}
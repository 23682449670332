import { Autocomplete, Button, Container, Grid, TextField } from "@mui/material";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext, fetchGet, fetchPost } from "wcz-layout";
import Material from "../models/Material";
import PaginationResponse from "../models/base/PaginationResponse";
import { useMutation, useQuery } from "@tanstack/react-query";
import { eqRoomMaterialUrl } from "../utils/BaseUrl";
import PaginationFilter from "../models/base/PaginationFilter";
import { SwapHoriz } from "@mui/icons-material";
import { GridToolbar, TableContainer } from "wcz-x-data-grid";
import { DataGridPremium, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { MaterialCategory } from "../models/enums/MaterialCategory";
import Item from "../models/Item";
import { useDebounce } from "use-debounce";

interface RequestData {
    materialId: string;
    itemIds: string[];
}

const getMaterialByPnPaginationFilter = (value: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "and",
            filters: [
                {
                    field: "partNumber",
                    operator: "contains",
                    value: value
                },
                //{
                //    field: "hasItems",
                //    operator: "eq",
                //    value: true
                //},
            ]
        },
        pageNumber: 0,
        pageSize: 12
    };
};

export default function SerialNumberTransfer() {
    const [materials, setMaterials] = useState<Material[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchValueDebounced] = useDebounce(searchValue, 500);
    const [from, setFrom] = useState<Material | null>(null);
    const [to, setTo] = useState<Material | null>(null);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const { changeTitle, t } = useContext(LayoutContext);

    useEffect(() => changeTitle(t("SerialNumberTransfer")), []);

    useEffect(() => { getMaterials(); }, [searchValueDebounced]);

    const { refetch: getMaterials, isFetching: isFetchingMaterial } = useQuery<PaginationResponse<Material>>(["material", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search`, getMaterialByPnPaginationFilter(searchValue)), {
        enabled: false,
        onSuccess: data => setMaterials(data.content)
    });

    const { data: items = [], refetch } = useQuery<Item[], Response>(["items", "materialId", from?.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/material/${from?.id}`, signal), {
        enabled: !!from?.id
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'serialNumber', headerName: "SN", width: 220, },
        { field: 'materialCategory', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory), },
        { field: 'location', headerName: t("Location"), width: 180, valueGetter: ({ value }) => value?.name, },
        { field: 'keeper', headerName: t("Keeper"), width: 220, },
        { field: 'vendorSerialNumber', headerName: `${t("Vendor")} SN`, width: 180, },
        { field: 'vendorPartNumber', headerName: `${t("Vendor")} PN`, width: 180, },
        { field: 'remark', headerName: t("Remark"), width: 350, },
    ] as GridColDef[], []);

    const { mutate: transferItems } = useMutation((req: RequestData) => fetchPost(`${eqRoomMaterialUrl}/v1/item/transfer`, req), {
        onSuccess: refetch
    });

    const handleOnTransfer = useCallback(() => {
        if (!to) return;

        transferItems({
            materialId: to.id,
            itemIds: rowSelectionModel as string[]
        });
    }, [to, rowSelectionModel]);

    return (
        <Fragment>
            <Container sx={{ my: 2 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={5} >
                        <Autocomplete
                            options={materials}
                            getOptionLabel={(option) => option.partNumber}
                            value={from}
                            loading={isFetchingMaterial}
                            autoHighlight
                            onChange={(e, value) => { setFrom(value); setSearchValue(""); }}
                            onInputChange={(e, value) => setSearchValue(value)}
                            onBlur={() => getMaterials()}
                            renderInput={(params) => <TextField {...params} label={t("From")} fullWidth size="small" margin="dense" />}
                        />
                    </Grid>

                    <Grid item xs={1} sx={{ textAlign: "center" }}>
                        <SwapHoriz fontSize="large" />
                    </Grid>

                    <Grid item xs={5} >
                        <Autocomplete
                            options={materials}
                            getOptionLabel={(option) => option.partNumber}
                            value={to}
                            loading={isFetchingMaterial}
                            autoHighlight
                            onChange={(e, value) => { setTo(value); setSearchValue(""); }}
                            onInputChange={(e, value) => setSearchValue(value)}
                            onBlur={() => getMaterials()}
                            renderInput={(params) => <TextField {...params} label={t("To")} fullWidth size="small" margin="dense" />}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <Button variant="contained" disabled={!from || !to} onClick={handleOnTransfer}>{t("Transfer")}</Button>
                    </Grid>
                </Grid>
            </Container>

            <TableContainer height={{ xs: "calc(100vh - 140px)", sm: "calc(100vh - 148px)" }}>
                <DataGridPremium rows={items} columns={columns} components={{ Toolbar: GridToolbar }}
                    checkboxSelection onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)} rowSelectionModel={rowSelectionModel} />
            </TableContainer>
        </Fragment>
    );
}
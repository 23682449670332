import { Close } from "@mui/icons-material";
import { Autocomplete, Divider, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { FileViewer } from "wcz-file";
import { LayoutContext, fetchPost } from "wcz-layout";
import CycleCount from "../../models/CycleCount";
import Item from "../../models/Item";
import Material from "../../models/Material";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import { useCreateCycleCountDetail } from "../../services/CycleCountService";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";

const getMaterialByDescriptionPaginationFilter = (value: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "and",
            filters: [
                {
                    field: "description",
                    operator: "contains",
                    value: value
                },
            ]
        },
        pageNumber: 0,
        pageSize: 12
    };
};

const getItemBySnPaginationFilter = (value: string, partNumber: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "and",
            filters: [
                {
                    field: "serialNumber",
                    operator: "contains",
                    value: value
                },
                {
                    field: "materialPartNumber",
                    operator: "eq",
                    value: partNumber
                },
            ]
        },
        pageNumber: 0,
        pageSize: 12
    };
};

interface AddListItemProps {
    locationId: string;
    locationName: string | undefined,
    cycleCount: CycleCount;
    refetch: () => void;
    close: () => void;
}

export const AddListItem: React.FC<AddListItemProps> = ({ locationId, locationName, close, cycleCount, refetch }) => {
    const { t } = useContext(LayoutContext);
    const [materials, setMaterials] = useState<Material[]>([]);
    const [selectedMaterial, setSelectedMaterial] = useState<Material | undefined>(undefined);
    const [searchMaterial, setSearchMaterial] = useState<string>("");

    const [items, setItems] = useState<Item[]>([]);
    const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
    const [searchItem, setSearchItem] = useState<string>("");

    const { refetch: getMaterials, isFetching: isFetchingMaterial } = useQuery<PaginationResponse<Material>>(["material", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search`, getMaterialByDescriptionPaginationFilter(searchMaterial)), {
        onSuccess: data => {
            setMaterials(data.content);
        }
    });

    useEffect(() => { getMaterials(); }, [searchMaterial]);

    const { refetch: getItems, isFetching: isFetchingItems } = useQuery<PaginationResponse<Item>>(["item", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/item/search`, getItemBySnPaginationFilter(searchItem, selectedMaterial!.partNumber)), {
        enabled: false,
        onSuccess: data => setItems(data.content)
    });

    useEffect(() => {
        if (selectedMaterial?.hasItems)
            getItems();
    }, [searchItem, selectedMaterial]);

    const { mutate } = useCreateCycleCountDetail(cycleCount.id, {
        onSuccess: () => handleClose()
    });

    const handleClose = useCallback(() => {
        setSearchMaterial("");
        setSelectedMaterial(undefined);
        setSearchItem("");
        setSelectedItem(undefined);
        refetch();
        close();
    }, []);

    const handleOnQuantityBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        if (!selectedMaterial) return;

        mutate({
            locatedAtId: locationId,
            locatedAt: locationName!,
            foundQuantity: Number(e.target.value),
            materialId: selectedMaterial.id,
            partNumber: selectedMaterial.partNumber
        });
    }, [selectedMaterial, locationId]);

    useEffect(() => addItem(), [selectedItem]);

    const addItem = useCallback(() => {
        if (!selectedMaterial || !selectedItem) return;

        mutate({
            locatedAtId: locationId,
            locatedAt: locationName!,
            foundQuantity: 1,
            materialId: selectedMaterial.id,
            partNumber: selectedMaterial.partNumber,
            itemId: selectedItem.id,
            serialNumber: selectedItem.serialNumber
        });
    }, [selectedItem, selectedMaterial, locationId]);

    return (
        <Fragment key="newMaterial">
            <ListItem selected secondaryAction={(selectedMaterial && !selectedMaterial?.hasItems) &&
                <TextField id="add-material-quantity" size="small" onBlur={handleOnQuantityBlur} sx={{ width: '12.7ch' }} type="number" disabled={!selectedMaterial} InputProps={{
                    startAdornment: <InputAdornment position="start">Qty:</InputAdornment>,
                }} />
            }>

                <ListItemIcon>
                    <IconButton color="error" sx={{ p: 0 }} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </ListItemIcon>

                <ListItemText primary={
                    <Autocomplete
                        options={materials}
                        value={selectedMaterial}
                        getOptionLabel={(option) => option.description}
                        disableClearable
                        loading={isFetchingMaterial}
                        autoHighlight
                        onChange={(e, value) => {
                            setSelectedMaterial(value);
                            document.getElementById("add-material-quantity")?.focus();
                        }}
                        onInputChange={(e, value) => setSearchMaterial(value)}
                        renderInput={(params) => <TextField {...params} placeholder={t("Materials")!} sx={{ width: { xs: 250, sm: 300, md: 350, lg: 400, xl: 450 } }} variant="standard" margin="dense" autoFocus size="small" />}
                        renderOption={(props, option) => (
                            <ListItem {...props}>
                                <ListItemIcon>
                                    <FileViewer subId={option.id} disableActions disableTooltip sx={{ mr: 2 }} imageSize={75} />
                                </ListItemIcon>

                                <ListItemText primary={option.description} />
                            </ListItem>
                        )}
                    />}
                    secondary={selectedMaterial?.hasItems &&
                        <Autocomplete
                            id="add-item-autocomplete"
                            options={items}
                            getOptionLabel={(option) => option.serialNumber}
                            value={selectedItem}
                            disableClearable
                            loading={isFetchingItems}
                            autoHighlight
                            onChange={(e, value) => setSelectedItem(value)}
                            onInputChange={(e, value) => setSearchItem(value)}
                            renderInput={(params) => <TextField {...params} placeholder={t("Items")!} sx={{ width: { xs: 160, sm: 200 } }} variant="standard" margin="dense" size="small" />}
                        />
                    }
                />
            </ListItem>
            <Divider />
        </Fragment>
    );
};
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { LayoutContext, fetchGet } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { MaterialCategory } from "../../models/enums/MaterialCategory";
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function MaterialQuantityReport() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle("Material quantity Report"), []);

    const { data = [], isLoading } = useQuery(["materialQuantityReport"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/report/materialquantity`, signal), {
        refetchOnWindowFocus: true
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'partNumber', headerName: "PN", width: 180 },
        { field: 'category', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory) },
        { field: 'subCategory', headerName: t("SubCategory"), width: 220, },
        { field: 'description', headerName: t("Description"), width: 380 },
        { field: 'usableQuantity', headerName: t("Usable"), width: 130, type: 'number' },
        { field: 'warehouseQuantity', headerName: t("Warehouse"), width: 130, type: 'number' },
        { field: 'totalQuantity', headerName: t("Total"), width: 130, type: 'number' },
        { field: 'safetyStock', headerName: t("SafetyStock"), width: 180, type: 'number' },
        { field: 'project', headerName: t("Project"), width: 150 },
        { field: 'models', headerName: t("Models"), width: 250 },
        { field: 'isReturnable', headerName: t("Returnable"), width: 150, type: "boolean", },
        { field: 'locations', headerName: t("Locations"), width: 250, },
    ] as GridColDef[], [i18n.resolvedLanguage]);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "materialQuantityReport", export: true, } as GridToolbarProps }} loading={isLoading} />
        </TableContainer>
    );
}
import { Close } from '@mui/icons-material';
import { AppBar, Button, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { Upload as TusUpload } from 'tus-js-client';
import { getToken, LayoutContext } from 'wcz-layout';
import { fileUrl } from '../../utils/BaseUrl';

const appName: string = window.location.hostname;

interface SignatureDialogProps {
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
    orderId: string
}

export default function SignatureDialog(props: SignatureDialogProps) {
    const { user, t } = useContext(LayoutContext);
    const [signaturePad, setSignaturePad] = useState({} as any);
    const [isSaving, setIsSaving] = useState(false);

    const upload = async (file: File) => {
        // Create a new tus upload
        const upload = new TusUpload(file, {
            endpoint: fileUrl + "/v1/upload",
            chunkSize: 1048576, //1 MB                
            metadata: {
                appName: appName,
                subId: props.orderId,
                filename: file.name,
                filetype: file.type
            },
            headers: { "Authorization": `Bearer ${await getToken()}` },
            onError: (error) => { window.alert(error.message); setIsSaving(false); },
            onSuccess: () => { setIsSaving(false); props.onSuccess && props.onSuccess(); }
        });

        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then((previousUploads) => {
            // Found previous uploads so we select the first one. 
            if (previousUploads.length)
                upload.resumeFromPreviousUpload(previousUploads[0]);

            upload.start();
        });
    };

    const onSave = async () => {
        setIsSaving(true);
        const dataUrl: string = signaturePad.toDataURL('image/png');
        const file = await urltoFile(dataUrl, `signature-${user.id}.png`, "image/png");

        upload(file);
    };

    const urltoFile = (url: string, filename: string, type: string): Promise<File> => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: type }); })
        );
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen>
            <AppBar color="default" position="relative">
                <Toolbar>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>

                    <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>{t("Signature")}</Typography>

                    <Button onClick={() => signaturePad.clear()} sx={{ mr: 1 }}>{t("Delete")}</Button>
                    <Button color="primary" variant="contained" onClick={onSave} disabled={isSaving}>{t("Save")}</Button>
                </Toolbar>
            </AppBar>

            <SignaturePad ref={(ref: any) => setSignaturePad(ref)} redrawOnResize />
        </Dialog>
    );
}
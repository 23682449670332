import { GridRowId } from '@mui/x-data-grid-premium';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { fetchDelete, fetchGet, fetchPost, fetchPut, LayoutContext } from 'wcz-layout';
import CycleCount, { CreateCycleCount } from '../models/CycleCount';
import { CreateCycleCountDetail, UpdateCycleCountDetail as UpdateCycleCountDetailModel } from '../models/CycleCountDetail';
import { eqRoomMaterialUrl } from '../utils/BaseUrl';
import { useContext } from 'react';

const queryKey: string = 'cyclecount';

export function useGetCycleCounts<TQueryFnData = CycleCount[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/${queryKey}`, signal), options);
}

export function useGetCycleCount<TQueryFnData = CycleCount, TError = string, TData = TQueryFnData>(id: GridRowId | undefined, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn' | 'initialData'>) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/${queryKey}/${id}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: CycleCount) => void,
}

export function useCreateCycleCount(options?: UseCreateOptions) {
    return useMutation((model: CreateCycleCount) => fetchPost(`${eqRoomMaterialUrl}/v1/${queryKey}`, model), {
        onSuccess: async (model) => {
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

export function useCreateCycleCountDetail(id: string, options?: UseCreateOptions) {
    return useMutation((model: CreateCycleCountDetail) => fetchPost(`${eqRoomMaterialUrl}/v1/${queryKey}/${id}/detail`, model), {
        onSuccess: async (model) => {
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}

interface UseUpdateOptions {
    onSuccess?: () => void
}

export function useStartCycleCount(options?: UseUpdateOptions) {
    return useMutation((id: string) => fetchPost(`${eqRoomMaterialUrl}/v1/${queryKey}/${id}/start`, null), {
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

export function UpdateCycleCountDetail(options?: UseUpdateOptions) {
    return useMutation((model: UpdateCycleCountDetailModel) => fetchPut(`${eqRoomMaterialUrl}/v1/${queryKey}/${model.cycleCountId}/detail/${model.id}`, model), {
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

export function useFinishCycleCount(options?: UseUpdateOptions) {
    return useMutation((id: string) => fetchPost(`${eqRoomMaterialUrl}/v1/${queryKey}/${id}/finish`, null), {
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

export function useConfirmCycleCount(options?: UseUpdateOptions) {
    return useMutation((model: CycleCount) => fetchPost(`${eqRoomMaterialUrl}/v1/${queryKey}/${model.id}/confirm`, model), {
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteCycleCount(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: GridRowId) => fetchDelete(`${eqRoomMaterialUrl}/v1/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<CycleCount[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};
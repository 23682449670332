import { Delete } from "@mui/icons-material";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import { useContext } from "react";
import { FileViewer } from "wcz-file";
import { LayoutContext } from "wcz-layout";
import { ReturnContext } from "../../contexts/ReturnContext";
import ReturnItem from "../../models/return/ReturnItem";
import MaterialDetailS from "../common/MaterialDetailS";
import ReturnMaterialItem from "./returnMaterial/ReturnMaterialItem";

interface ReturnMaterialProps {
    returnItem: ReturnItem
}

export default function ReturnMaterial(props: ReturnMaterialProps) {
    const { returnItem } = props;
    const returnContext = useContext(ReturnContext);
    const { t } = useContext(LayoutContext);

    const handleOnReasonChange = (value: string) => {
        returnContext.updateItemRemark(returnItem.id as string, value);
    };

    const getItemRemark = () => {
        const foundReturnItem: ReturnItem | undefined = returnContext.state.items.find(i => i.id === returnItem.id);
        if (foundReturnItem)
            return foundReturnItem.remark;

        return "";
    };

    return (
        <Grid container spacing={1} sx={{ my: 2 }}>
            <Grid item xs={6} sm={4} md={4} lg={5}>
                <Box sx={{ m: 0.5 }}>
                    <FileViewer subId={props.returnItem.materialId} disableActions />
                </Box>
            </Grid>

            <Grid item xs={6} sm={8} md={8} lg={7}>
                <Grid container justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={10} md={11}>
                        <MaterialDetailS materialId={returnItem.materialId} quantity={returnItem.quantity} />
                        {returnItem.materialItems?.map(item => <ReturnMaterialItem key={item.id} itemId={item.itemId} />)}
                    </Grid>

                    {!returnItem.materialItems.length &&
                        <Grid item xs={2} md={1}>
                            <IconButton onClick={() => returnContext.removeMaterial(returnItem.orderItemId)}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                <TextField size="small" placeholder={t("Reason")!} value={getItemRemark()} onChange={e => handleOnReasonChange(e.target.value)} />
            </Grid>
        </Grid>
    );
}
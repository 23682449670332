import { ShoppingCart, Undo } from '@mui/icons-material';
import { Badge, Fab, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import { OrderType } from "../../models/order/OrderType";
import Cart from "../../models/shoppingCart/Cart";
import CartItem from "../../models/shoppingCart/CartItem";
import { eqOrderUrl } from "../../utils/BaseUrl";
import CartDrawer from "./cartButton/CartDrawer";

interface CartButtonProps {
    addMaterial: string,
    setAddMaterial: (addMaterial: string) => void,
    children?: React.ReactNode,
    type: OrderType,
    refetch: () => void
}

export default function CartButton(props: CartButtonProps) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const { user, t } = useContext(LayoutContext);
    let prevScrollpos = window.pageYOffset;

    const { data, refetch } = useQuery<Cart, Response>(["cart"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/cart/${user.id}/${props.type}`, signal), {
        enabled: !!user.id
    });

    useEffect(() => {
        if (props.addMaterial)
            addCartItem(props.addMaterial);

    }, [props.addMaterial]);

    const addCartItem = async (addedMaterial: string) => {
        if (!data) return;

        const itemInCart: CartItem | undefined = data.items.find(i => i.materialId === addedMaterial);
        if (itemInCart) {
            return updateCartItemQuantity({ ...itemInCart, quantity: itemInCart.quantity + 1 });
        }

        createCartItem({
            cartId: data.id as string,
            materialId: addedMaterial,
            id: undefined,
            quantity: 1
        });
    };

    const { mutate: createCartItem } = useMutation((request: CartItem) => fetchPost(`${eqOrderUrl}/v1/cart/item`, request), {
        onSettled: () => props.setAddMaterial(""),
        onSuccess: () => { refetch(); props.refetch(); },
    });

    const { mutate: updateCartItemQuantity } = useMutation((request: CartItem) => fetchPut(`${eqOrderUrl}/v1/cart/item/${request.id}`, request), {
        onSettled: () => props.setAddMaterial(""),
        onSuccess: () => refetch(),
    });

    useEffect(() => {
        onScroll();
        // eslint-disable-next-line
    }, []);

    const onScroll = () => {
        window.onscroll = () => {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                setScrollDown(false);
            } else {
                setScrollDown(true);
            }
            prevScrollpos = currentScrollPos;
        };
    };

    return (
        <React.Fragment>
            <Fab color="primary" variant="extended" onClick={() => setDrawerOpen(true)} sx={{ position: 'fixed', bottom: 10, right: 10 }}>
                <Badge color="secondary" badgeContent={data?.items.length}>
                    {props.type === OrderType.Purchase ? <ShoppingCart /> : <Undo />}
                </Badge>
                {!scrollDown && <Typography sx={{ ml: 2 }}>{props.type === OrderType.Purchase ? t("Cart") : t("Return")}</Typography>}
            </Fab>

            <CartDrawer open={drawerOpen} setOpen={setDrawerOpen} cart={data} refetch={refetch} type={props.type} />
        </React.Fragment>
    );
}
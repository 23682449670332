import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPost, hasRole, LayoutContext } from "wcz-layout";
import Material from "../../../../models/Material";
import Order from "../../../../models/order/Order";
import OrderAddress from "../../../../models/order/OrderAddress";
import OrderAddressApiModel from "../../../../models/order/OrderAddressApiModel";
import { OrderAddressType } from "../../../../models/order/OrderAddressType";
import { OrderStatus } from "../../../../models/order/OrderStatus";
import { OrderType } from "../../../../models/order/OrderType";
import Cart from "../../../../models/shoppingCart/Cart";
import { eqOrderAddressUrl, eqOrderUrl, eqRoomMaterialUrl, peoplesoftUrl } from "../../../../utils/BaseUrl";
import { adminRole } from "../../../../utils/UserRoles";

interface Person {
    employeeId: string,
    nameTitleCase: string
}

interface OrderFormProps {
    refetch: () => void,
    type: OrderType,
    setOpen: (open: boolean) => void,
    cart: Cart | undefined
}

export default function OrderForm(props: OrderFormProps) {
    const [order, setOrder] = useState({} as Order);
    const [people, setPeople] = useState([] as string[]);
    const [customer, setCustomer] = useState("");
    const [warningShortageDialog, setWarningShortageDialog] = useState<boolean>(false);
    const { user, snackbar, t } = useContext(LayoutContext);

    useQuery<Person[], Response>(["people"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=companyCode==530;status==Active&fields=nameTitleCase,employeeId`, signal), {
        enabled: !people.length && !!user.name && props.type === OrderType.Purchase,
        onSuccess: (data) => {
            const filteredEmployees: Person[] = data.filter((person, index, array) =>
                index === array.findIndex((t) => (t.employeeId === person.employeeId))
            );
            const employees = filteredEmployees.map(person => `${person.nameTitleCase} (${person.employeeId})`);
            setPeople(employees);
        },
    });

    const { data: orderAddresses = [] } = useQuery<OrderAddressApiModel[], Response>(["orderAddresses-orderForm"], ({ signal }) => fetchGet(`${eqOrderAddressUrl}/v1/address?type=${OrderAddressType.Line}&type=${OrderAddressType.Workplace}`, signal));

    useEffect(() => {
        setOrder({
            ...order,
            status: OrderStatus.Created,
            type: props.type,
            addresses: [
                { key: "Line", value: "" },
                { key: "Workplace", value: "" }
            ]
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (people.length) {
            initializeCustomer();
        }

        // eslint-disable-next-line
    }, [people]);

    const initializeCustomer = () => {
        let person: string | undefined = people.find(p => p.includes(user.id.toUpperCase()));
        if (!person) {
            person = `${user.name} (${user.id.toUpperCase()})`;
        }
        setOrder({ ...order, customer: person });
        setCustomer(person);
    };

    const { mutate, isLoading } = useMutation((request: Order) => fetchPost(eqOrderUrl + "/v1/order", request), {
        onSettled: () => props.setOpen(false),
        onSuccess: () => { snackbar({ message: t("Ordered"), severity: "success" }); props.refetch(); setWarningShortageDialog(false); },
    });

    const onAddressChange = (newValue: string, index: number) => {
        const addresses: OrderAddress[] = order.addresses;
        addresses[index].value = newValue;
        setOrder({ ...order, addresses: addresses });
    };

    const handleOnCustomerChange = (value: string | null) => {
        if (!value)
            return;

        setOrder({ ...order, customer: value });
        setCustomer(value);
    };

    const handleSubmit = async () => {
        if (!props.cart)
            return;

        let showWarning: boolean = false;

        props.cart.items.forEach(async (item, index, array) => {
            const material: Material = await fetchGet(`${eqRoomMaterialUrl}/v1/material/${item.materialId}`, undefined);
            if (item.quantity > material.usableQuantity)
                showWarning = true;

            if ((index + 1) === array.length) {
                if (showWarning)
                    setWarningShortageDialog(true);
                else
                    mutate(order);
            }
        });

    };

    return (
        <React.Fragment>
            {props.type === OrderType.Purchase &&
                <div>
                    <Autocomplete options={people} autoHighlight value={customer} onChange={(event, value) => handleOnCustomerChange(value)} freeSolo disableClearable
                        renderInput={(params) => <TextField {...params} label={t("OrderFor")} variant="standard" margin="dense" fullWidth />} disabled={!hasRole(adminRole)} />

                    {order && order.addresses && order.addresses.map((address, index) =>
                        <Autocomplete
                            key={address.key}
                            options={orderAddresses.filter(a => a.type === address.key as OrderAddressType).map(a => a.name)}
                            autoHighlight
                            disableClearable
                            value={address.value}
                            onChange={(event, value) => value && onAddressChange(value, index)}
                            renderInput={(params) => <TextField {...params} label={address.key} variant="standard" margin="dense" fullWidth required />} />
                    )}
                </div>
            }

            <TextField multiline label={t("Remark")} variant="standard" margin="dense" fullWidth value={order.remark} onChange={e => setOrder({ ...order, remark: e.target.value })}
                helperText={`${order.remark?.length ?? 0}/500`} error={order.remark ? order.remark.length > 500 : false} />

            <Box sx={{ float: 'right' }}>
                <Button variant="contained" onClick={handleSubmit} disabled={isLoading || !!order.addresses?.filter(a => !a.value).length}>{props.type === OrderType.Purchase ? t("Order") : t("Return")}</Button>
            </Box>

            <Dialog open={warningShortageDialog} onClose={() => setWarningShortageDialog(false)}>
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("SomeItemsAreOutOfStockAndWillNotBeDeliveredToYou")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setWarningShortageDialog(false)}>{t("Disagree")}</Button>
                    <Button onClick={() => mutate(order)} autoFocus>{t("Agree")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
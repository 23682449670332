import { ChevronRight } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Container, Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext, hasRole } from "wcz-layout";
import { adminRole, whKeeperRole } from "../../utils/UserRoles";

export default function Reports() {
    const { changeTitle, t } = useContext(LayoutContext);
    const navigate = useNavigate();

    useEffect(() => changeTitle(t("Reports")), [t]);

    return (
        <Container>
            {(hasRole(whKeeperRole)) &&
                <Card variant="outlined" sx={{ my: 2 }}>
                    <CardActionArea onClick={() => navigate("consumption")}>
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5" component="div">Consumption Report</Typography>
                                </Grid>
                                <Grid item sx={{ my: 'auto' }}>
                                    <ChevronRight />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            }

            {(hasRole(whKeeperRole)) &&
                <Card variant="outlined" sx={{ my: 2 }}>
                    <CardActionArea onClick={() => navigate("shortage")}>
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5" component="div">Shortage Report</Typography>
                                </Grid>
                                <Grid item sx={{ my: 'auto' }}>
                                    <ChevronRight />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            }

            {(hasRole(whKeeperRole) || hasRole(adminRole)) &&
                <Card variant="outlined" sx={{ my: 2 }}>
                    <CardActionArea onClick={() => navigate("receiving")}>
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5" component="div">Receiving Report</Typography>
                                </Grid>
                                <Grid item sx={{ my: 'auto' }}>
                                    <ChevronRight />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            }

            <Card variant="outlined" sx={{ my: 2 }}>
                <CardActionArea onClick={() => navigate("tooling")}>
                    <CardContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" component="div">Tooling Report</Typography>
                            </Grid>
                            <Grid item sx={{ my: 'auto' }}>
                                <ChevronRight />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Card variant="outlined" sx={{ my: 2 }}>
                <CardActionArea onClick={() => navigate("materialQuantity")}>
                    <CardContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" component="div">Material quantity Report</Typography>
                            </Grid>
                            <Grid item sx={{ my: 'auto' }}>
                                <ChevronRight />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Card variant="outlined" sx={{ my: 2 }}>
                <CardActionArea onClick={() => navigate("item")}>
                    <CardContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" component="div">Item Report</Typography>
                            </Grid>
                            <Grid item sx={{ my: 'auto' }}>
                                <ChevronRight />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Card variant="outlined" sx={{ my: 2 }}>
                <CardActionArea onClick={() => navigate("safety-stock")}>
                    <CardContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" component="div">Safety stock Report</Typography>
                            </Grid>
                            <Grid item sx={{ my: 'auto' }}>
                                <ChevronRight />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            {(hasRole(whKeeperRole) || hasRole(adminRole)) &&
                <Card variant="outlined" sx={{ my: 2 }}>
                    <CardActionArea onClick={() => navigate("hold-scrap")}>
                        <CardContent>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5" component="div">Hold Scrap Report</Typography>
                                </Grid>
                                <Grid item sx={{ my: 'auto' }}>
                                    <ChevronRight />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            }
        </Container>
    );
}
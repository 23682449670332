import { Check } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { DataGridPremium, GridColDef, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext, hasRole } from "wcz-layout";
import { AutocompleteEditInputCell, CellClassName, EditableHeader, GridToolbar, GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import CycleCount from "../../models/CycleCount";
import CycleCountDetail from "../../models/CycleCountDetail";
import { CycleCountDetailAction } from "../../models/enums/CycleCountDetailAction";
import { CycleCountDetailStatus } from "../../models/enums/CycleCountDetailStatus";
import { CycleCountStatus } from "../../models/enums/CycleCountStatus";
import { useConfirmCycleCount } from "../../services/CycleCountService";
import { whKeeperRole } from "../../utils/UserRoles";

interface FinishedCycleCountProps {
    cycleCount: CycleCount;
    refetch: () => void;
}
export const FinishedCycleCount: React.FC<FinishedCycleCountProps> = ({ cycleCount, refetch }) => {
    const { t, i18n, snackbar } = useContext(LayoutContext);
    const [updatedCycleCount, setUpdatedCycleCount] = useState<CycleCount>({} as CycleCount);
    const navigate = useNavigate();

    useEffect(() => setUpdatedCycleCount(cycleCount), [cycleCount]);

    const { mutate } = useConfirmCycleCount({
        onSuccess: () => {
            refetch();
            snackbar({ message: t("CycleCountConfirmed") });
            navigate("/cycle-counts");
        }
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'locatedAt', headerName: t("LocatedAt"), width: 220, },
        { field: 'partNumber', headerName: "PN", width: 170, },
        { field: 'serialNumber', headerName: "SN", width: 170, },
        { field: 'status', headerName: t("Status"), width: 170, type: 'singleSelect', valueOptions: Object.values(CycleCountDetailStatus) },
        {
            field: 'expectedQuantity', headerName: t("ExpectedQuantity"), width: 140, type: 'number',
            cellClassName: (params) => params.row.status === CycleCountDetailStatus.Updated ? CellClassName.BackgroundWarning : '',
        },
        {
            field: 'foundQuantity', headerName: t("FoundQuantity"), width: 140, type: 'number',
            cellClassName: (params) => params.row.status === CycleCountDetailStatus.Updated ? CellClassName.BackgroundWarning : '',
        },
        {
            field: 'confirmedQuantity', headerName: t("ConfirmedQuantity"), width: 140, type: 'number',
            editable: true, renderHeader: cycleCount.status === CycleCountStatus.Finished && EditableHeader,
            cellClassName: (params) => params.row.status === CycleCountDetailStatus.Updated ? CellClassName.BackgroundWarning : '',
            renderCell: ({ value, row }) => value ?? <Typography sx={{ opacity: 0.3 }}>{row.foundQuantity}</Typography>
        },
        {
            field: 'action', headerName: t("Action"), width: 220, valueFormatter: ({ value }) => t(value),
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={Object.values(CycleCountDetailAction)} getOptionLabel={value => t(value)} />,
            editable: true, renderHeader: cycleCount.status === CycleCountStatus.Finished && EditableHeader,
            cellClassName: (params) => params.row.status === CycleCountDetailStatus.Updated ? CellClassName.BackgroundWarning : '',
        },
        { field: 'scanned', headerName: t("Scanned"), width: 170, type: 'boolean', },
    ] as GridColDef[], [i18n.language, cycleCount]);

    const processRowUpdate = async (row: GridRowModel<GridValidRowModel>): Promise<GridValidRowModel> => {
        setUpdatedCycleCount({
            ...updatedCycleCount,
            cycleCountDetails: updatedCycleCount.cycleCountDetails?.map(d => d.id === row.id ? row as CycleCountDetail : d)
        });

        return row;
    };

    const confirmCycleCount = useCallback(() => mutate({
        ...updatedCycleCount,
        cycleCountDetails: updatedCycleCount.cycleCountDetails?.map(d => ({ ...d, confirmedQuantity: d.confirmedQuantity ?? d.foundQuantity }))
    }), [updatedCycleCount]);

    return (
        <TableContainer>
            <DataGridPremium rows={updatedCycleCount.cycleCountDetails ?? []} columns={columns} slots={{ toolbar: GridToolbar }}
                isCellEditable={props => {
                    switch (props.field) {
                        case 'confirmedQuantity': return cycleCount.status === CycleCountStatus.Finished;
                        case 'action': return props.row.status === CycleCountDetailStatus.Updated && cycleCount.status === CycleCountStatus.Finished;
                        default: return false;
                    }
                }}
                editMode="cell" processRowUpdate={processRowUpdate} slotProps={{
                    toolbar: {
                        hideAddRecord: true, items: cycleCount.status === CycleCountStatus.Finished ? [{ title: t('Confirm'), onClick: confirmCycleCount, icon: <Check />, hidden: !hasRole(whKeeperRole) }] : undefined
                    } as GridToolbarProps
                }} />
        </TableContainer>
    );
};
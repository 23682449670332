import { Autocomplete, Container, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { fetchPost, LayoutContext } from "wcz-layout";
import MaterialReceivingCard from "../../components/material/MaterialReceivingCard";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import Material from "../../models/Material";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";

const getMaterialByPnPaginationFilter = (value: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "and",
            filters: [
                {
                    field: "partNumber",
                    operator: "contains",
                    value: value
                },
            ]
        },
        pageNumber: 0,
        pageSize: 12
    };
};

export default function MaterialReceive() {
    const [searchValue, setSearchValue] = useState<string>("");
    const [materials, setMaterials] = useState<Material[]>([]);
    const [material, setMaterial] = useState<Material | null>(null);
    const { changeTitle, t } = useContext(LayoutContext);

    useEffect(() => changeTitle(t("MaterialReceiving")), []);

    const { refetch, isFetching: isFetchingMaterial } = useQuery<PaginationResponse<Material>>(["material", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search`, getMaterialByPnPaginationFilter(searchValue)), {
        enabled: false,
        onSuccess: data => setMaterials(data.content)
    });

    useEffect(() => { refetch(); }, [searchValue]);

    return (
        <Container maxWidth="sm" sx={{ mt: 5 }}>
            <Autocomplete
                fullWidth
                options={materials}
                getOptionLabel={option => option.partNumber}
                onInputChange={(e, value) => setSearchValue(value)}
                onChange={(e, value) => setMaterial(value)}
                loading={isFetchingMaterial}
                renderInput={params => <TextField {...params} variant="outlined" margin="dense" label="PN" autoFocus />}
            />

            {material && <MaterialReceivingCard materialId={material.id} key={material.id} />}
        </Container>
    );
}
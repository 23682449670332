import { Build, ContactMail, History, Home, Inventory2, Layers, LocationOn, QueryStats, Storage } from '@mui/icons-material';
import { teal, yellow } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FileProvider } from 'wcz-file';
import { LayoutProvider, LeftDrawerItem, hasRole, useUser } from 'wcz-layout';
import packageJson from '../package.json';
import { ReturnProvider } from './contexts/ReturnContext';
import CycleCountPage from './pages/CycleCountPage';
import CycleCounts from './pages/CycleCounts';
import HomePage from './pages/HomePage';
import Items from './pages/Items';
import Locations from './pages/Locations';
import MaterialTransfer from './pages/MaterialTransfer';
import OrderAddresses from './pages/OrderAddresses';
import SelfCycleCount from './pages/SelfCycleCount';
import SerialNumberTransfer from './pages/SerialNumberTransfer';
import StorePage from './pages/Store';
import ToolingParts from './pages/ToolingParts';
import MaterialReceive from './pages/material/MaterialReceive';
import Materials from './pages/material/Materials';
import Orders from './pages/order/Orders';
import PurchaseOrders from './pages/order/PurchaseOrders';
import ReturnBorrowed from './pages/order/ReturnBorrowed';
import ReturnOrders from './pages/order/ReturnOrders';
import ShortageOrders from './pages/order/ShortageOrders';
import ConsumptionReport from './pages/report/ConsumptionReport';
import MaterialQuantityReport from './pages/report/MaterialQuantityReport';
import ReceivingReport from './pages/report/ReceivingReport';
import Reports from './pages/report/Reports';
import ShortageReport from './pages/report/ShortageReport';
import ToolingReport from './pages/report/ToolingReport';
import { fileUrl } from './utils/BaseUrl';
import { whKeeperRole } from './utils/UserRoles';
import ItemReport from './pages/report/ItemReport';
import SafetyStockReport from './pages/report/SafetyStockReport';
import HoldScrapReport from './pages/report/HoldScrapReport';

export default function App() {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "Locations", path: "/locations", icon: <LocationOn fontSize="large" />, hidden: !hasRole(whKeeperRole.concat(whKeeperRole)) },
        { title: "Material", path: "/material", icon: <Storage fontSize="large" />, hidden: !hasRole(whKeeperRole.concat(whKeeperRole)) },
        { title: "ToolingParts", path: "/toolingParts", icon: <Build fontSize="large" />, hidden: !hasRole(whKeeperRole.concat(whKeeperRole)) },
        { title: "Items", path: "/items", icon: <Layers fontSize="large" />, hidden: !hasRole(whKeeperRole.concat(whKeeperRole)) },
        { title: "Orders", path: "/orders", icon: <History fontSize="large" /> },
        { title: "OrderAddresses", path: "/orderAddresses", icon: <ContactMail fontSize="large" />, hidden: !hasRole(whKeeperRole.concat(whKeeperRole)) },
        { title: "Reports", path: "/report", icon: <QueryStats fontSize="large" /> },
        { title: "CycleCount", path: "/cycle-counts", icon: <Inventory2 fontSize="large" />, hidden: !hasRole(whKeeperRole.concat(whKeeperRole)) },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={teal[700]} secondaryColor={yellow[500]} leftDrawerItems={leftDrawerItems}>
                <FileProvider baseUrl={fileUrl}>
                    <ReturnProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Routes>
                                <Route path='/' element={<HomePage />} />
                                <Route path='/material' element={<Materials />} />
                                <Route path='/toolingParts' element={<ToolingParts />} />
                                <Route path='/material/receive' element={<MaterialReceive />} />
                                <Route path='/items' element={<Items />} />
                                <Route path='/locations' element={<Locations />} />
                                <Route path='/store' element={<StorePage />} />
                                <Route path='/orders' element={<Orders />} />
                                <Route path='/orders/type/purchase' element={<PurchaseOrders />} />
                                <Route path='/orders/type/return' element={<ReturnOrders />} />
                                <Route path='/orders/type/shortage' element={<ShortageOrders />} />
                                <Route path='/return/:employeeId' element={<ReturnBorrowed />} />
                                <Route path='/orderAddresses' element={<OrderAddresses />} />
                                <Route path='/report' element={<Reports />} />
                                <Route path='/report/consumption' element={<ConsumptionReport />} />
                                <Route path='/report/shortage' element={<ShortageReport />} />
                                <Route path='/report/receiving' element={<ReceivingReport />} />
                                <Route path='/report/tooling' element={<ToolingReport />} />
                                <Route path='/report/materialQuantity' element={<MaterialQuantityReport />} />
                                <Route path='/report/item' element={<ItemReport />} />
                                <Route path='/report/safety-stock' element={<SafetyStockReport />} />
                                <Route path='/report/hold-scrap' element={<HoldScrapReport />} />
                                <Route path='/materialTransfer' element={<MaterialTransfer />} />
                                <Route path='/serialNumberTransfer' element={<SerialNumberTransfer />} />
                                <Route path='/cycle-counts' element={<CycleCounts />} />
                                <Route path='/cycle-count/:id' element={<CycleCountPage />} />
                                <Route path='/cycle-count/self/:id' element={<SelfCycleCount />} />
                            </Routes>
                        </LocalizationProvider>
                    </ReturnProvider>
                </FileProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
}
import { Box, Button, Grid, InputAdornment, TextField, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FileViewer } from 'wcz-file';
import { LayoutContext } from 'wcz-layout';
import { RemoveReturnOrderItem, ReturnContext } from '../../../contexts/ReturnContext';
import OrderItem from '../../../models/order/OrderItem';
import MaterialDetailS from '../../common/MaterialDetailS';
import MyOrderItemMaterialItem from './myOrderItem/MyOrderItemMaterialItem';
import { Info } from '@mui/icons-material';

interface MyOrderItemProps {
    item: OrderItem
}

export default function MyOrderItem(props: MyOrderItemProps) {
    const { item } = props;
    const [quantity, setQuantity] = useState<number>(item.quantity - item.returnedQuantity);
    const [returnQuantity, setReturnQuantity] = useState<number>(item.quantity - item.returnedQuantity);
    const returnContext = useContext(ReturnContext);
    const { t } = useContext(LayoutContext);

    const handleOnMaterialReturn = () => {
        if (item.material?.hasActiveCycleCount) return;
         
        returnContext.addMaterial(returnQuantity, item.id as string, item.materialId);
        setQuantity(quantity - returnQuantity);
    };

    useEffect(() => {
        if (returnContext.removeReturnOrderItem.orderItemId === item.id) {
            setQuantity(quantity + 1);
            returnContext.setRemoveReturnOrderItem({} as RemoveReturnOrderItem);
        }
    }, [returnContext.removeReturnOrderItem]);

    const handleOnChange = (newQuantity: number) => {
        if (newQuantity <= quantity && newQuantity > 0)
            setReturnQuantity(newQuantity);
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={1} sx={{ my: 2 }}>
                <Grid item xs={6} sm={4}>
                    <Box sx={{ m: 0.5 }}>
                        <FileViewer subId={item.materialId} disableActions />
                    </Box>
                </Grid>

                <Grid item xs={6} sm={8}>
                    <MaterialDetailS materialId={item.materialId} quantity={quantity} />
                    {item.materialItems.map(materialItem => <MyOrderItemMaterialItem key={materialItem.id} materialItem={materialItem} />)}

                    {(!!quantity && !item.materialItems.length) &&
                        <TextField label={t("ReturnQuantity")} type="number" fullWidth size="small" sx={{ mt: 2 }}
                            value={returnQuantity} onChange={e => handleOnChange(Number(e.target.value))}
                            InputProps={{ endAdornment: <InputAdornment position="start"> / {quantity} {t("Pcs").toLowerCase()}</InputAdornment> }} />
                    }
                </Grid>

                {(!!quantity && !item.materialItems.length) &&
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={handleOnMaterialReturn} sx={{ mr: 1 }} disabled={item.material?.hasActiveCycleCount}
                            endIcon={item.material?.hasActiveCycleCount && <Tooltip title={t("MaterialIsCurrentlyBeingCycleCounted")}><Info /></Tooltip>}>{t("Return")}</Button>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}
import React, { useCallback, useContext, useMemo, useState } from "react";
import CustomDialog from "../common/CustomDialog";
import { LayoutContext } from "wcz-layout";
import { GridToolbar, TableContainer } from "wcz-x-data-grid";
import { DataGridPremium, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import Location from "../../models/Location";

interface LocationDialogProps {
    open: boolean;
    setLocationDialogOpen: (open: boolean) => void;
    locations: Location[];
    setSelectedLocations: (locations: Location[]) => void;
}

export const LocationDialog: React.FC<LocationDialogProps> = ({ open, setLocationDialogOpen, locations, setSelectedLocations }) => {
    const { t, i18n } = useContext(LayoutContext);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const onClose = useCallback(() => {
        setLocationDialogOpen(false);
        setSelectedLocations(rowSelectionModel.map(id => locations.find(l => l.id === id) as Location));
    }, [rowSelectionModel, locations]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'name', headerName: t("Name"), width: 220, },
        { field: 'canHaveMoreMaterial', headerName: t("CanHaveMoreMaterial"), width: 250, type: 'boolean' },
    ] as GridColDef[], [i18n.language]);

    return (
        <CustomDialog open={open} onClose={onClose} title={t("Locations")} disablePadding maxWidth="md" color="primary">
            <TableContainer height="80vh">
                <DataGridPremium rows={locations} columns={columns} components={{ Toolbar: GridToolbar }}
                    checkboxSelection onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)} rowSelectionModel={rowSelectionModel} />
            </TableContainer>
        </CustomDialog>
    );
};
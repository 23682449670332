import { Container, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { uniq } from "lodash";
import { useContext, useEffect, useState } from "react";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import RefetchButton from "../../components/common/RefetchButton";
import PuchaseOrder from "../../components/order/PuchaseOrder";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import Material from "../../models/Material";
import Order from "../../models/order/Order";
import { OrderStatus } from "../../models/order/OrderStatus";
import { OrderType } from "../../models/order/OrderType";
import { eqOrderUrl, eqRoomMaterialUrl } from "../../utils/BaseUrl";

const initMaterialPaginationFilter = (materialIds: string[]): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "or",
            filters: materialIds.map(materialId => ({
                field: "id",
                operator: "eq",
                value: materialId
            }))
        }
    };
};

export default function PurchaseOrders() {
    const [orders, setOrders] = useState<Order[]>([] as Order[]);
    const [enabledPrefetch, setEnabledPrefetch] = useState<boolean>(false);
    const [newOrders, setNewOrders] = useState<Order[]>([] as Order[]);
    const { changeTitle, t } = useContext(LayoutContext);

    const { refetch } = useQuery<Order[], Response>(["orders", "type", OrderType.Purchase], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order?type=${OrderType.Purchase}&status=${OrderStatus.Created}&status=${OrderStatus.Picked}`, signal), {
        onSuccess: data => {
            setOrders(data);
            setNewOrders([]);
            setTimeout(() => setEnabledPrefetch(true), 300);
        }
    });

    useQuery<Order[], Response>(["orders", "type", OrderType.Purchase, "new"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order?type=${OrderType.Purchase}&status=${OrderStatus.Created}&status=${OrderStatus.Picked}`, signal), {
        enabled: enabledPrefetch,
        onSuccess: data => {
            const newOrders = data.filter(order => !orders.some(o => o.id === order.id));
            setNewOrders(newOrders);
        },
        refetchInterval: 1000 * 60 * 0.5,
        refetchIntervalInBackground: true
    });

    useEffect(() => changeTitle(t("PurchaseOrders")), []);

    const { data: materials = {} as PaginationResponse<Material> } = useQuery<PaginationResponse<Material>>(["material", "search", OrderType.Purchase], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search`,
        initMaterialPaginationFilter(uniq(orders.flatMap(o => o.items.flatMap(i => i.materialId))))), {
        enabled: !!orders.length,
    });

    return (
        <Grid container justifyContent="center">
            {orders.map(order =>
                <Grid item xs={11} key={order.id}>
                    <Container maxWidth="sm">
                        <PuchaseOrder order={order} allOrders={orders} setAllOrders={setOrders} allMaterials={materials.content ?? []} />
                    </Container>
                </Grid>
            )}

            {!!newOrders.length && <RefetchButton onClick={refetch} value={`${newOrders.length} ${t("NewOrders").toLowerCase()}`} />}
        </Grid>
    );
}
import { useEffect, useState } from 'react';

export const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');

    useEffect(() => {
        let lastScrollTop = 0;

        const onScroll = () => {
            const scrollTop = window.scrollY;
            const isScrollingUp = scrollTop < lastScrollTop;

            setScrollDirection(isScrollingUp ? 'up' : 'down');
            lastScrollTop = scrollTop;
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return scrollDirection;
};

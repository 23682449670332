import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { MaterialCategory } from "../../models/enums/MaterialCategory";
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function ShortageReport() {
    const { changeTitle, t } = useContext(LayoutContext);

    useEffect(() => changeTitle("Shortage Report"), []);

    const { data = [], isLoading } = useQuery(["shortageReport"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/report/shortage`, signal), {
        refetchOnWindowFocus: true
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'partNumber', headerName: "PN", width: 250 },
        { field: 'description', headerName: t("Description"), width: 400 },
        { field: 'category', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory) },
        { field: 'orderedBy', headerName: t("User"), width: 250 },
        { field: 'quantity', headerName: t("Quantity"), width: 150, type: 'number' },
        { field: 'date', headerName: t("Date"), width: 200, type: 'date', valueFormatter: ({ value }) => moment(value).formatDate(), valueGetter: ({ value }) => value && new Date(value) },
        { field: 'workplace', headerName: t("Workplace"), width: 180 },
        { field: 'project', headerName: t("Project"), width: 200 },
    ] as GridColDef[], [t]);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "shortageReport", export: true, } as GridToolbarProps }} loading={isLoading} />
        </TableContainer>
    );
}
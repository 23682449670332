import { Add, Close, Done, Edit, FilterAlt, Lock } from '@mui/icons-material';
import { Alert, BottomNavigation, BottomNavigationAction, Box, Button, ButtonGroup, Chip, Container, Divider, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutContext } from 'wcz-layout';
import { DialogScanner } from 'wcz-scan';
import { AddListItem } from '../components/selfCycleCount/AddListItem';
import CycleCount from '../models/CycleCount';
import { UpdateCycleCountDetail, useGetCycleCount } from '../services/CycleCountService';
import { CycleCountDetailStatus } from '../models/enums/CycleCountDetailStatus';
import useSound from 'use-sound';
import CycleCountDetail from '../models/CycleCountDetail';
import { FileViewer } from 'wcz-file';
import { CycleCountStatus } from '../models/enums/CycleCountStatus';
import { SelfCycleCountMaterialDescription } from '../components/cycleCount/SelfCycleCountMaterialDescription';

let quantityChangeTimeoutId: NodeJS.Timeout | null = null;

export default function SelfCycleCount() {
    const { id } = useParams();
    const { changeTitle, t, i18n, user, snackbar } = useContext(LayoutContext);
    const [cycleCountDetails, setCycleCountDetails] = useState<CycleCountDetail[]>([]);
    const [materialScannerOpen, setMaterialScannerOpen] = useState<boolean>(false);
    const [selectedMaterial, setSelectedMaterial] = useState<string>('');
    const [addNewMaterialOpen, setAddNewMaterialOpen] = useState<boolean>(false);
    const [beep] = useSound(require('../utils/sounds/beep.mp3'));

    useEffect(() => changeTitle(t("CycleCount")), [i18n.language]);

    const { data: cycleCount = {} as CycleCount, refetch } = useGetCycleCount(id, {
        onSuccess: data => setCycleCountDetails(data.cycleCountDetails.filter(d => d.locatedAtId === user.id))
    });

    const handleOnAddNewMaterial = useCallback(() => {
        setAddNewMaterialOpen(true);
        setSelectedMaterial("");
        window.scrollTo(0, 0);
    }, [cycleCount]);

    const handleOnMaterialScan = useCallback((text: string) => {
        const foundSerialNumber = cycleCountDetails.find(d => d.serialNumber === text && d.partNumber === selectedMaterial);
        if (foundSerialNumber) {
            return updateAsync({ id: foundSerialNumber.id, cycleCountId: cycleCount.id, foundQuantity: 1, scanned: true }).then(() => beep());
        }

        const foundPartNumber = cycleCountDetails.find(d => d.partNumber === text);
        if (foundPartNumber) {
            setSelectedMaterial(text);
            beep();

            setTimeout(() => {
                const partNumberInput = document.getElementById(`${text}-input-qty`);
                if (partNumberInput)
                    return window.setTimeout(() => partNumberInput.focus(), 0);
            }, 0);
        } else {
            snackbar({ message: t("MaterialNotFound", { material: text }), severity: 'error' });
        }
    }, [cycleCount, cycleCountDetails, selectedMaterial]);

    const { mutateAsync: updateAsync } = UpdateCycleCountDetail({ onSuccess: () => refetch() });

    const handleOnSerialNumberChange = useCallback((detail: CycleCountDetail, value: number) => () => {
        updateAsync({ id: detail.id, cycleCountId: cycleCount.id, foundQuantity: value, scanned: false });
    }, [cycleCount]);

    const handleOnQuantityChange = useCallback((detail: CycleCountDetail) => (e: React.FocusEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (quantityChangeTimeoutId)
            clearTimeout(quantityChangeTimeoutId);

        quantityChangeTimeoutId = setTimeout(() => {
            updateAsync({ id: detail.id, cycleCountId: cycleCount.id, foundQuantity: value, scanned: selectedMaterial === detail.partNumber });
        }, 700);
    }, [cycleCount, selectedMaterial]);

    const clearSelectedMaterial = useCallback(() => setSelectedMaterial(''), []);

    const anyWaiting = useMemo(() => cycleCountDetails.some(d => d.status === CycleCountDetailStatus.Waiting), [cycleCountDetails]);

    if (cycleCount.status !== CycleCountStatus.Started)
        return (
            <Box sx={{ position: "relative", height: { xs: "calc(100vh - 125px)", sm: "calc(100vh - 133px)" } }}>
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                    <Lock color="disabled" fontSize="large" />
                    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>{t("CycleCountIsBeingCompleted")}</Typography>
                </Box>
            </Box>
        );

    return (
        <Container sx={{ mt: 1, mb: 10 }}>
            {anyWaiting ?
                <Fragment>
                    <Typography variant="h6">{t("PleaseCountYourMaterials")}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>{t("AnyDiscrepanciesInTheNumberOfMaterialsWillThenBeManuallyChecked")}</Typography>
                </Fragment>
                :
                <Alert severity="success" sx={{ mb: 2 }}>{t("CycleCountCompleted")}</Alert>
            }

            <List>
                {selectedMaterial && <Chip icon={<FilterAlt />} label={selectedMaterial} onDelete={clearSelectedMaterial} sx={{ ml: 1, my: 1 }} />}

                {addNewMaterialOpen && <AddListItem locationId={user.id} locationName={user.name} close={() => setAddNewMaterialOpen(false)} cycleCount={cycleCount} refetch={refetch} />}

                {cycleCountDetails.filter(d => d.partNumber.includes(selectedMaterial)).map((value) =>
                    <Box id={value.partNumber} key={value.id}>
                        <ListItem secondaryAction={
                            value.serialNumber ?
                                <ButtonGroup>
                                    <Button color="success" variant={value.foundQuantity === 1 ? "contained" : "outlined"} onClick={handleOnSerialNumberChange(value, 1)}><Done /></Button>
                                    <Button color="error" variant={value.foundQuantity === 0 ? "contained" : "outlined"} onClick={handleOnSerialNumberChange(value, 0)}><Close /></Button>
                                </ButtonGroup>
                                :
                                <TextField id={`${value.partNumber}-input-qty`} size="small" defaultValue={value.foundQuantity} onChange={handleOnQuantityChange(value)} sx={{ width: '18.7ch' }} type="number" InputProps={{
                                    startAdornment: <InputAdornment position="start">{t("Quantity")}:</InputAdornment>,
                                }} />
                        }>

                            <ListItemIcon>
                                <FileViewer subId={value.materialId} disableActions disableTooltip sx={{ mr: 2 }} />
                            </ListItemIcon>

                            <ListItemText primary={value.partNumber} secondary={
                                <Fragment>
                                    <SelfCycleCountMaterialDescription materialId={value.materialId} />
                                    <Typography>{value.serialNumber ? `SN: ${value.serialNumber}` : `${t("Quantity")}: ${value.expectedQuantity}`}</Typography>
                                    {value.status === CycleCountDetailStatus.Ok && <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>{t("QuantityOk")}<Done color="success" fontSize="small" sx={{ ml: 1 }} /></Typography>}
                                    {value.status === CycleCountDetailStatus.Updated && <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>{t("QuantityMismatch", { from: value.expectedQuantity, to: value.foundQuantity })}<Edit color="info" fontSize="small" sx={{ ml: 1 }} /></Typography>}
                                    {value.status === CycleCountDetailStatus.New && <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>{t("NewMaterial")}<Add color="warning" fontSize="small" sx={{ ml: 1 }} /></Typography>}
                                </Fragment>}
                            />
                        </ListItem>
                        <Divider />
                    </Box>
                )}
            </List>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels>
                    <BottomNavigationAction
                        label={t("AddMaterial")}
                        icon={<Add />}
                        onClick={handleOnAddNewMaterial}
                        disabled={!isEmpty(addNewMaterialOpen)}
                    />
                </BottomNavigation>
            </Paper>

            <DialogScanner open={materialScannerOpen} setOpen={setMaterialScannerOpen} onResult={handleOnMaterialScan} title={t("ScanMaterial")!} />
        </Container>
    );
}
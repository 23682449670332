import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { LayoutContext, fetchGet } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";

export default function HoldScrapReport() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    useEffect(() => changeTitle("Hold Scrap Report"), []);

    const { data = [], isLoading } = useQuery(["holdScrapReport"], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/report/holdScrap`, signal), {
        refetchOnWindowFocus: true
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'partNumber', headerName: "PN", width: 180, },
        { field: 'serialNumber', headerName: "SN", width: 180, },
        { field: 'locationName', headerName: t("Location"), width: 150, },
        { field: 'description', headerName: t("Description"), width: 400, },
        { field: 'locationQuantity', headerName: t("Quantity"), width: 130, type: 'number', },
        { field: 'remark', headerName: t("Remark"), width: 400, },
        { field: 'unitPrice', headerName: t("UnitPrice"), width: 150, type: 'number', },
        { field: 'totalPrice', headerName: t("TotalPrice"), width: 150, type: 'number', },
    ] as GridColDef[], [i18n.resolvedLanguage]);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "holdScrapReport", export: true, } as GridToolbarProps }} loading={isLoading} />
        </TableContainer>
    );
}
import { ExpandMore, SwapHoriz } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, CardContent, Grid, InputAdornment, Menu, TextField, Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid-premium";
import { GridToolbar } from "wcz-x-data-grid";
import { useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import Item from "../../models/Item";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { eqRoomMaterialUrl, eqRoomUrl } from "../../utils/BaseUrl";

interface TransferMaterial {
    fromLocation: Location | null,
    toLocation: Location | null,
    materialId: GridRowId,
    quantity: number
}

interface TransferItem {
    toLocation: Location | null,
    itemId: GridRowId | null
}

interface MaterialCardProps {
    id: string,
    quantity: number,
    location: Location,
    locations: Location[],
    refetch: () => void
}

export default function MaterialCard(props: MaterialCardProps) {
    const [locationsMenu, setLocationsMenu] = useState<{ mouseX: number, mouseY: number } | null>(null);
    const [transferMaterial, setTransferMaterial] = useState<TransferMaterial>({ materialId: props.id, quantity: props.quantity, fromLocation: props.location, toLocation: null } as TransferMaterial);
    const [transferItem, setTransferItem] = useState<TransferItem>({ itemId: null, toLocation: null } as TransferItem);
    const { t } = useContext(LayoutContext);

    const { data: material = {} as Material } = useQuery<Material>(["material", props.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${props.id}`, signal), {
        enabled: !!props.id
    });

    const { data: allMaterialItems = [], refetch: refetchItems } = useQuery<Item[], Response>(["items", "materialId", material.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/material/${material.id}`, signal), {
        enabled: material.hasItems
    });

    const items: Item[] = useMemo(() => allMaterialItems.filter(i => i.location?.id === props.location.id), [allMaterialItems, props.location]);

    const handleTransferMaterial = () => {
        mutateTransferMaterial({
            materialId: transferMaterial.materialId,
            quantity: transferMaterial.quantity,
            fromLocationId: transferMaterial.fromLocation?.id,
            toLocationId: transferMaterial.toLocation!.id
        });
    };

    const { mutate: mutateTransferMaterial } = useMutation((req: any) => fetchPut(`${eqRoomUrl}/v1/material/${req.fromLocationId}/transfer`, req), {
        onSuccess: () => {
            props.refetch();
            setTransferMaterial({ materialId: props.id, quantity: props.quantity, fromLocation: props.location, toLocation: null });
        }
    });

    const handleTransferItem = () => {
        mutateTransferItem({
            itemId: transferItem.itemId,
            toLocationId: transferItem.toLocation!.id
        });
    };

    const { mutate: mutateTransferItem } = useMutation((req: any) => fetchPut(`${eqRoomUrl}/v1/material/item/${req.itemId}/transfer`, req), {
        onSuccess: () => {
            props.refetch();
            handleMenuClose();
            refetchItems();
        }
    });

    const openMenu = (event: React.MouseEvent, id: GridRowId) => {
        event.preventDefault();
        setLocationsMenu({ mouseX: event.clientX, mouseY: event.clientY, });
        setTransferItem({ ...transferItem, itemId: id });
    };

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params) => [
                <GridActionsCellItem icon={<SwapHoriz />} label={t("Transfer")} onClick={(e: any) => openMenu(e, params.id)} />,
            ],
        },
        { field: 'serialNumber', headerName: "SN", width: 220, maxWidth: 500, },
        { field: 'keeper', headerName: t("Keeper"), width: 200, maxWidth: 500, },
        { field: 'vendorSerialNumber', headerName: `${t("Vendor")} SN`, width: 180, maxWidth: 500, },
        { field: 'remark', headerName: t("Remark"), width: 300, maxWidth: 500, },
        // eslint-disable-next-line
    ] as GridColDef[], []);

    const handleMenuClose = () => {
        setLocationsMenu(null);
        setTransferItem({ itemId: null, toLocation: null });
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography sx={{ width: '20%', flexShrink: 0 }}> {`${material.partNumber} (${props.quantity})`} </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{`${t("Vendor")}: ${material.vendor}`}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                {material.hasItems ?
                    <Box sx={{ display: "flex", height: 400 }}>
                        <DataGridPremium rows={items} columns={columns} components={{ Toolbar: GridToolbar }} />
                    </Box>
                    :
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField variant="standard" type="number" fullWidth value={transferMaterial.quantity} onChange={e => setTransferMaterial({ ...transferMaterial, quantity: Number(e.target.value) })}
                                    label={t("Quantity")} InputProps={{
                                        endAdornment: <InputAdornment position="start">{t("Pcs")}</InputAdornment>,
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    value={transferMaterial.toLocation}
                                    options={props.locations.filter(l => {
                                        let materialLocationIds: string[] | undefined = material.locations?.map(l => l.id);
                                        if (!materialLocationIds)
                                            return true;

                                        materialLocationIds = materialLocationIds.filter(id => id !== props.location.id);
                                        return materialLocationIds.includes(l.id);
                                    })}
                                    getOptionLabel={option => option.name}
                                    onChange={(e, value) => { setTransferMaterial({ ...transferMaterial, toLocation: value }); }}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Location")} />}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                <Button variant="contained" onClick={handleTransferMaterial} disabled={!transferMaterial.toLocation}>{t("Transfer")}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                }

                <Menu open={locationsMenu !== null} onClose={handleMenuClose} anchorReference="anchorPosition" variant="menu"
                    anchorPosition={locationsMenu !== null ? { top: locationsMenu.mouseY, left: locationsMenu.mouseX } : undefined}>
                    <Box sx={{ width: 340, px: 2 }} role="presentation">
                        <Typography variant="h6">{t("Transfer")}</Typography>

                        <Autocomplete
                            options={props.locations.filter(l => {
                                let materialLocationIds: string[] | undefined = material.locations?.map(l => l.id);
                                if (!materialLocationIds)
                                    return true;

                                materialLocationIds = materialLocationIds.filter(id => id !== props.location.id);
                                return materialLocationIds.includes(l.id);
                            })}
                            value={transferItem.toLocation}
                            getOptionLabel={o => o.name}
                            autoHighlight
                            onChange={(e, value) => setTransferItem({ ...transferItem, toLocation: value })}
                            renderInput={(params) => <TextField {...params} label={t("Location")} fullWidth variant="standard" margin="normal" required autoFocus />}
                        />

                        <Button variant="contained" onClick={handleTransferItem} disabled={!transferItem.toLocation?.id} sx={{ float: "right", mb: 1 }}>{t("Transfer")}</Button>
                    </Box>
                </Menu>
            </AccordionDetails>
        </Accordion>
    );
}
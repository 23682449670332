import { Close } from "@mui/icons-material";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import OrderAddressApiModel from "../../../models/order/OrderAddressApiModel";
import { OrderAddressType } from "../../../models/order/OrderAddressType";
import { eqOrderAddressUrl, eqOrderUrl } from "../../../utils/BaseUrl";

interface DepartmentDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    orderId: string,
    onSuccess: () => void
}

export default function DepartmentDialog(props: DepartmentDialogProps) {
    const [address, setAddress] = useState("");
    const { t } = useContext(LayoutContext);

    const { data: orderAddresses = [] } = useQuery<OrderAddressApiModel[], Response>(["orderAddresses", "department"], ({ signal }) => fetchGet(`${eqOrderAddressUrl}/v1/address?type=${OrderAddressType.Department}`, signal), {
        enabled: props.open,
        staleTime: 100000
    });

    const handleOnClose = () => {
        props.setOpen(false);
        setAddress("");
    };

    const { mutate: addDepartment, isLoading: isAddingDepartment } = useMutation((req: any) => fetchPut(eqOrderUrl + "/v1/order/address", req), {
        onSuccess: () => props.onSuccess(),
    });

    const handleOnSaveClick = () => {
        addDepartment({
            orderId: props.orderId,
            key: OrderAddressType.Department,
            value: address
        });
    };

    return (
        <Dialog open={props.open} onClose={handleOnClose} fullWidth>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <DialogTitle>{t("Department")}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={handleOnClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <Autocomplete
                    options={orderAddresses.map(a => a.name)}
                    autoHighlight
                    value={address}
                    onChange={(event, value) => setAddress(value ?? "")}
                    renderInput={(params) => <TextField {...params} placeholder={t("Department")!} variant="standard" margin="dense" fullWidth required autoFocus />} />
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleOnSaveClick} disabled={!address || isAddingDepartment}>{t("Save")}</Button>
            </DialogActions>
        </Dialog>
    );
}
import { Box, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { fetchGet, fetchPost, fetchPut, LayoutContext, newGuid } from "wcz-layout";
import Location from "../../models/Location";
import Material from "../../models/Material";
import Return from "../../models/return/Return";
import ReturnItem from "../../models/return/ReturnItem";
import ReturnItemLocation from "../../models/return/ReturnItemLocation";
import { eqOrderUrl, eqRoomMaterialUrl, eqRoomUrl } from "../../utils/BaseUrl";
import MaterialDetailS from "../common/MaterialDetailS";
import ReturnOrderItemMaterialItem from "./returnOrderItem/ReturnOrderItemMaterialItem";

interface ReturnOrderItemProps {
    item: ReturnItem,
    returnOrder: Return,
    refetch: () => void,
    highlight: boolean
}

export default function ReturnOrderItem(props: ReturnOrderItemProps) {
    const { t } = useContext(LayoutContext);
    const isMutating = !!useIsMutating();

    const { data: material = {} as Material, refetch: refetchMaterial } = useQuery<Material>(["material", props.item.materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${props.item.materialId}`, signal), {
        enabled: !!props.item.materialId,
    });

    const { data: locations = [] } = useQuery<Location[]>(["location", "material", props.item.materialId], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location?materialId=${material?.id}`, signal), {
        enabled: !!material?.id,
    });

    const { data: scrap } = useQuery<Location>(["location", "SCRAP"], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location/420d4cb1-591d-4b7d-9936-2455013f29e0`, signal), {
        enabled: !!material?.id,
        staleTime: 1000 * 60 * 60 * 24 * 7
    });

    const { data: hold } = useQuery<Location>(["location", "HOLD"], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location/61fbe788-42ad-4884-ead5-bb5de6d84686`, signal), {
        enabled: !!material?.id,
        staleTime: 1000 * 60 * 60 * 24 * 7
    });

    const { mutate } = useMutation((req: Return) => fetchPut(eqOrderUrl + "/v1/return/" + req.id, req), {
        onSuccess: () => props.refetch()
    });

    const { mutate: addLocation } = useMutation((req: { materialId: string, locationId: string }) => fetchPost(`${eqRoomUrl}/v1/material`, req), {
        onSuccess: () => refetchMaterial()
    });

    const handleOnFocus = (inputLocation: Location) => {
        if (inputLocation.name === "HOLD" || inputLocation.name === "SCRAP") {
            addLocation({ materialId: material.id, locationId: inputLocation.id });
        }
    };

    const handleOnLocationQuantityChange = (quantity: number, inputLocation: Location) => {
        if (quantity > props.item.quantity)
            return;

        const returnItemLocation: ReturnItemLocation | undefined = props.item.locations.find(l => l.locationId === inputLocation.id);
        if (returnItemLocation) {
            returnItemLocation.quantity = quantity;
            mutate({
                ...props.returnOrder,
                items: props.returnOrder.items.map(item => {
                    if (item.id === props.item.id)
                        return {
                            ...item,
                            locations: item.locations.map(location => {
                                if (location.id === inputLocation.id)
                                    return returnItemLocation;
                                return location;
                            })
                        };
                    else
                        return item;
                })
            });
        } else {
            const newLocations = props.item.locations;
            newLocations.push({ id: newGuid(), locationId: inputLocation.id, quantity: quantity, returnItemId: props.item.id! });

            mutate({
                ...props.returnOrder,
                items: props.returnOrder.items.map(item => {
                    if (item.id === props.item.id)
                        return { ...item, locations: newLocations };
                    else
                        return item;
                })
            });
        }
    };

    const getLocations = (): Location[] => {
        let options: Location[] = locations;

        if (!options.find(l => l.name === "SCRAP") && scrap)
            options.push(scrap);

        if (!options.find(l => l.name === "HOLD") && hold)
            options.push(hold);

        return options;
    };

    const remaining = useMemo(() => {
        const totalQuantity = props.item.quantity;

        const materialItemsPickedQuantity = props.item.materialItems.filter(mi => mi.locationId).length;
        const locationsSumPickedQuantity = props.item.locations.reduce((acc, curr) => acc + curr.quantity, 0);

        return totalQuantity - (materialItemsPickedQuantity + locationsSumPickedQuantity);
    }, [props.item, isMutating]);

    const highlight = useMemo(() => {
        return props.highlight && remaining > 0;
    }, [props.highlight, props.item, isMutating]);

    return (
        <CardContent sx={theme => ({ bgcolor: highlight ? theme.palette.mode === "dark" ? "rgba(255, 220, 50, 0.2)" : "rgba(255, 220, 0, 0.2)" : undefined })}>
            <MaterialDetailS materialId={props.item.materialId} quantity={props.item.quantity} />
            {props.item.remark && <Typography variant="subtitle2" color="text.secondary"><b>{t("Remark")}: </b>{props.item.remark}</Typography>}

            {material.hasItems ?
                <Box>
                    {props.item.materialItems?.map(materialItem =>
                        <ReturnOrderItemMaterialItem key={materialItem.id} materialItem={materialItem} locations={locations} returnItem={props.item} returnOrder={props.returnOrder} hold={hold} scrap={scrap} />
                    )}
                </Box>
                :
                <Grid container>
                    {getLocations().map(location =>
                        <Grid item xs={12} key={location.id}>
                            <TextField
                                type="number"
                                size="small"
                                fullWidth
                                label={`${t("Location")}: ${location.name}`}
                                value={props.item.locations.find(l => l.locationId === location.id)?.quantity ?? null}
                                onChange={e => handleOnLocationQuantityChange(Number(e.target.value), location)}
                                onFocus={() => handleOnFocus(location)}
                                sx={{ my: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    )}
                </Grid>
            }
        </CardContent>
    );
}
import { Close } from "@mui/icons-material";
import { Alert, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import Material from "../../../../models/Material";
import { OrderType } from "../../../../models/order/OrderType";
import CartItem from "../../../../models/shoppingCart/CartItem";
import { eqOrderUrl, eqRoomMaterialUrl } from "../../../../utils/BaseUrl";

interface CartItemDetailProps {
    item: CartItem,
    onSettled: () => void,
    disableQuantityCheck?: boolean,
    type: OrderType
}

export default function CartItemDetail(props: CartItemDetailProps) {
    const [helperText, setHelperText] = useState("");
    const { t } = useContext(LayoutContext);

    const { mutate, isLoading } = useMutation((request: CartItem) => fetchPut(`${eqOrderUrl}/v1/cart/item/${request.id}`, request), {
        onSettled: () => props.onSettled(),
    });

    const { data: material = {} as Material } = useQuery<Material, Response>(["material", props.item.materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/material/${props.item.materialId}`, signal), {
        enabled: !!props.item.materialId,
        onSuccess: (data) => checkAvailableQuantity(props.item.quantity, data.usableQuantity),
    });

    const checkAvailableQuantity = (cartItemQuantity: number, usableQuantity: number = 0) => {
        if (props.type === OrderType.Return)
            return;

        if (cartItemQuantity > usableQuantity)
            setHelperText(`${usableQuantity} ${t("PiecesInStock")}.`);
        else
            setHelperText("");
    };

    const onQuantityChange = (quantity: number) => {
        mutate({ ...props.item, quantity: quantity });
        checkAvailableQuantity(quantity, material?.usableQuantity);
    };

    return (
        <Grid item xs={12} sx={{ my: 1 }} key={props.item.id}>
            <Grid container justifyContent="space-between">
                <Grid item xs={9}>
                    {material.subCategory && <Typography variant="subtitle1">{material.subCategory.name}</Typography>}
                    {material.type && <Typography variant="subtitle2" color="text.secondary"><b>{t("Type")}: </b>{material.type}</Typography>}
                    {material.description && <Typography variant="subtitle2" color="text.secondary"><b>{t("Description")}: {material.description}</b></Typography>}
                    {!!material.models?.length && <Typography variant="subtitle2" color="text.secondary"><b>Model: </b>{material.models?.map(m => m.name)}</Typography>}
                </Grid>

                <Grid item xs={3}>
                    <TextField onChange={e => onQuantityChange(Number(e.target.value))} type="number" size="small" value={props.item.quantity} InputProps={{
                        readOnly: isLoading,
                        endAdornment: (
                            <InputAdornment position="end" onClick={() => onQuantityChange(0)}>
                                <Close color="error" fontSize="small" sx={{ cursor: 'pointer' }} />
                            </InputAdornment>
                        ),
                    }} />
                </Grid>

                {helperText && <Alert severity="warning" sx={{ width: '100%', mt: 0.5 }}>{helperText}</Alert>}
            </Grid>
        </Grid>
    );
}